export default [
    {
        category: "sustainabilityCause",
        cause: "climateChange"
    },
    {
        category: "socialCause",
        cause: "humanRights"
    },
    {
        category: "drugsCause",
        cause: "psychDrugs"
    },
    {
        category: "peaceCause",
        cause: "military"
    },
    {
        category: "peaceCause",
        cause: "slavery"
    },
    {
        category: "foodCause",
        cause: "sugar"
    },
    {
        category: "foodCause",
        cause: "vegan"
    }
];
