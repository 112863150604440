import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Settings from "./Settings";
import sidebar from "containers/Sidebar/reducer";
import setting from 'containers/Settings/reducer';
import 'config/firebase';

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  setting,
  sidebar
});

export default reducers;
