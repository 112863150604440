import * as firebase from "firebase";
import { keys } from "./firebaseSwitch";
import { dataConfig } from "config/dataConfig";
import { getFromStorage, setInStorage } from "localStorageService";
const dbKey = getFromStorage('db');

// jason's attempts to Service Worker-fix this
const clientKey = getFromStorage('clientKey') || dataConfig.clientKey;

if (!dbKey) {
    //  here you can set the default db to load
    setInStorage('db', dataConfig.dbUrlKey);
    console.log('🔥🔥🔥🔥🔥 !dbKey so we setInStorage from dataConfig.dbUrlKey:', dataConfig.dbUrlKey);
}
if (!clientKey) {
	window.location.reload(true);
    //  here you can set the default db to load
    // var missingClientKey = /(xxx|local|j.alpha)/.test(window.location.hostname) ? 'ESG' :
	   //  /(esg|xxxx|x.)/.test(window.location.hostname) ? 'ESG' :
	   //  /(equality|localx)/.test(window.location.hostname) ? 'Equ' :
	   //  /(sdg|locxx)/.test(window.location.hostname) ? 'SDG' :
	   //  /(pope|frances)/.test(window.location.hostname) ? 'Pope' :
	   //  /(psych|sci.)/.test(window.location.hostname) ? 'Psych' :
	   //  /(veg|xxx)/.test(window.location.hostname) ? 'Veg' :
	   //  /(green|locx|xxxx)/.test(window.location.hostname) ? 'Green' :
	   //  /(vj.|demo.|vijay.)/.test(window.location.hostname) ? 'VJ' :  
    //     	'ESG';   
    // setInStorage('clientKey', missingClientKey);
    // console.log('🔥🔥🔥🔥🔥 !clientKey so we setInStorage:', missingClientKey);
}


const firebaseKeys = keys();
firebase.initializeApp(firebaseKeys);

export const databaseRef = firebase.database().ref();

export const version = databaseRef.child("version");
export const allCauses = databaseRef.child("allCauses");
export const defaultCategories = databaseRef.child("defaultCategories");
export const customCategories = databaseRef.child("customCategories");
export const defaultCausesSetup = databaseRef.child("defaultCausesSetup");
export const settings = databaseRef.child("settings");
