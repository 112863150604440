import React, { useRef } from "react";
import {
    Slider
} from "antd";
import {
    iaText
} from "config/iaText";

import { convertFromStep, convertToStep } from "util/helpers";
// import { dataConfig } from "config/dataConfig";

export default ({
    cause = {},
    step = {},
		onMouseUp
}) => {
    const sliderStep = (step && step[cause.causeId]) || {};
		let nameRef = useRef();
    function formatter(value) {
        const path = `${cause.causeCategory}.${cause.causeId}.${
            convertFromStep(value)
        }`;
        const showSliderValue = (val) => {
            switch(val) {
                case 1: return "‒1";
                case 2: return "";
                case 3: return "+1";
                case 4: return "+2";
                default: return "-2";
            }
        };
        const text = convertFromStep(value) === 50 ? "off" : iaText()[path];
        return `${text ? text : ""}${(text && (text !== "off")) ? "：" : ""}${showSliderValue(value)} `;
    }

    const updateValue = (step, cause, triggerApi) => {
        onMouseUp(step, cause, triggerApi);
		}

		// const sliderValue = nameRef.current && step[nameRef.current.props.className];
        // className = {cause.causeId + " background_" + dbKey}
		return (
        <Slider
            className = {cause.causeId}
            tipFormatter = {formatter}
            min = {0}
            max = {4}
						step = {1}
						ref={nameRef} 
            // defaultValue = {
            //     sliderStep.hasOwnProperty('step') ? sliderStep.step : 2
            // }
            value={sliderStep.hasOwnProperty('step') ? sliderStep.step : convertToStep(cause.causeWeight)}
            onChange={step => updateValue(step, cause, true)}
            onAfterChange = {step => updateValue(step, cause)}
            dots
        />
    );
};
