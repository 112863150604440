import {
    defaultCausesSetup,
    defaultCategories,
    version
} from "config/firebase";
import {
    GET_CAUSES_SUCCESS,
    GET_CAUSES_FAIL,
    TICKERS_START,
    TICKERS_SUCCESS,
    TICKERS_FAIL,
    SET_CAUSE,
    SET_TICKER,
    IMPACT_POINTS_SUCCESS,
    IMPACT_POINTS_FAIL,
    SET_IMPACT_POINT_FAIL,
    RESET_APP_DB_VERSION,
		RESET,
		SET_GEO_LOCATION
} from "./constants";
import { isEmpty } from "lodash";
import { getFromStorage } from "localStorageService";
import { getPortfolio, getTickers, setImpactPoint } from "requestService";
import store from "../../appRedux/store";
import { dataConfig } from "config/dataConfig";

export const checkDbVersion = () => {
    return dispatch => {
        version.on(
            "value",
            snapshot => {
                const currentVersion = snapshot.val();
				const dbVersion = localStorage.getItem("dbVersion") || 0;
				if (+dbVersion === 0) {
					localStorage.setItem("dbVersion", currentVersion);
				} else if (+dbVersion !== currentVersion && +dbVersion > 0) {
                    localStorage.setItem("dbVersion", currentVersion);
                    localStorage.setItem("emailSubmitted", null);
                    console.log("🔥dbVersion:", currentVersion);
                    dispatch({
                        type: RESET_APP_DB_VERSION
                    });
                    dispatch(reset());
                }
            },
            err => {
                console.warn(" DB VERSION ERROR", err);
            }
        );
    };
};

export const getAllCauses = () => {
    return dispatch => {
        defaultCategories.on("value", catSnapshot => {
            defaultCausesSetup.on("value", snapshot => {
                if (snapshot) {
                    dispatch({
                        type: GET_CAUSES_SUCCESS,
                        causes: snapshot.val(),
                        categories: catSnapshot.val()
                    });

                    // const tickerCausesFromStorage = getFromStorage(
                    //     "tickerCauses"
                    // );
                    // if (tickerCausesFromStorage) {
                    //     dispatch(getTickersData(tickerCausesFromStorage));
                    // }
                } else {
                    dispatch({
                        type: GET_CAUSES_FAIL
                    });
                }
            });
        });
    };
};

export const setGeoLocation = response => {
	return dispatch => {
			dispatch({
					type: SET_GEO_LOCATION,
					geo_ip: response
			});
	};
};

export const storeSelectedCauses = cause => {
    return dispatch => {
        dispatch({
            type: SET_CAUSE,
            cause
        });
    };
};

export const storeSelectedCause = cause => {
    return dispatch => {
        dispatch({
            type: SET_TICKER,
            cause
        });
    };
};

export const setImpactPointAction = data => dispatch => {
    setImpactPoint(data)
        .then(benchmark => {
            dispatch({
                type: TICKERS_SUCCESS,
                payload: {
                    tickers: benchmark.tickers,
                    returns: benchmark.returns,
                    benchmark
                }
            });
            const currentState = store.getState();

            dispatch(
                getPortfolioData(
                    currentState.sidebar.tickerCauses,
                    benchmark.tickers
                )
            );
        })
        .catch(() => {
            dispatch({
                type: SET_IMPACT_POINT_FAIL
            });
        });
};

export const getPortfolioData = (sliders, tickers) => {
    return dispatch => {
        const data = {
            sliders,
            portfolio: tickers || {},
            portfolios: dataConfig.impactPointsCompanies
        };

        getPortfolio(data)
            .then(portfolio => {
                dispatch({
                    type: IMPACT_POINTS_SUCCESS,
                    payload: portfolio
                });
            })
            .catch(() => {
                dispatch({
                    type: IMPACT_POINTS_FAIL
                });
            });
    };
};

export const getTickersData = causes => {
	const included = getFromStorage("included");
	const excluded = getFromStorage("excluded");

    const payload = {
        years: ["2016", "2017", "2018", "2019", "2020", "2021"],
        causes,
        size: dataConfig.portfolioSize,
        index: "sp500",
				api: 4,
				included: included || [],
				excluded: excluded || []
    };

    return dispatch => {
        dispatch({
            type: TICKERS_START
        });
        if (!isEmpty(causes)) {
            getTickers(payload)
                .then(response => {
                    const { returns, tickers, ...rest } = response;
                    dispatch({
                        type: TICKERS_SUCCESS,
                        payload: {
                            returns,
                            tickers,
                            benchmark: rest
                        }
                    });

                    const currentState = store.getState();
                    dispatch(
                        getPortfolioData(
                            currentState.sidebar.tickerCauses,
                            tickers
                        )
                    );
                })
                .catch(() => {
                    dispatch({
                        type: TICKERS_FAIL
                    });
                });
        }
    };
};

export const reset = () => {
    return dispatch => {
        dispatch({
            type: RESET
        });
        dispatch(getAllCauses());
    };
};
