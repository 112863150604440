export default [
    {
        category: "sustainabilityCause",
        cause: "climateChange"
    },
    {
        category: "socialCause",
        cause: "humanRights"
    },
    {
        category: "socialCause",
        cause: "immigration"
    },
    {
        category: "peaceCause",
        cause: "military"
    },
    {
        category: "peaceCause",
        cause: "slavery"
    }
];
