import React, { Component } from "react";
import { connect } from "react-redux";
import { dataConfig } from "config/dataConfig";
import { getFromStorage } from "localStorageService";
import {
    onNavStyleChange,
    toggleCollapsedSideNav
} from "appRedux/actions/Setting";
import {
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_MINI_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    TAB_SIZE,
    THEME_TYPE_LITE
} from "../../constants/ThemeSetting";

class SidebarLogo extends Component {
    render() {
        const dbKey = getFromStorage('db') || 'x';
        const { width, themeType, navCollapsed } = this.props;
        let logoTextValue = dataConfig.logoTextValue;
        let logoTextValueSub = dataConfig.logoTextValueSub;
        let logoTextValueSmall = dataConfig.logoTextValueSmall;
        let logoTextValue2 = dataConfig.logoTextValue2;
        let logoImageRef = dataConfig.logoImageRef;

        let { navStyle } = this.props;
        if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
            navStyle = NAV_STYLE_DRAWER;
        }
        return (
            <nav
                className={`gx-layout-sider-header background_x background_${dbKey}`}
            >
                {navStyle === NAV_STYLE_MINI_SIDEBAR ? (
                    <div className="gx-linebar">
                        <i
                            className={`gx-icon-btn icon icon-${
                                navStyle === NAV_STYLE_MINI_SIDEBAR
                                    ? "menu-unfold"
                                    : "menu-fold"
                            } ${
                                themeType !== THEME_TYPE_LITE
                                    ? "gx-text-white"
                                    : ""
                            }`}
                            onClick={() => {
                                if (navStyle === NAV_STYLE_DRAWER) {
                                    this.props.toggleCollapsedSideNav(
                                        !navCollapsed
                                    );
                                } else if (navStyle === NAV_STYLE_FIXED) {
                                    // this.props.onNavStyleChange(
                                    //     NAV_STYLE_MINI_SIDEBAR
                                    // );
                                    // const sliders = document.getElementsByClassName(
                                    //     "sliders-wrapper"
                                    // );
                                    // console.log(sliders);
                                    // sliders[0].style.display = "none";
                                } else if (
                                    navStyle ===
                                    NAV_STYLE_NO_HEADER_MINI_SIDEBAR
                                ) {
                                    this.props.toggleCollapsedSideNav(
                                        !navCollapsed
                                    );
                                } else {
                                    const sliders = document.getElementsByClassName(
                                        "sliders-wrapper"
                                    );
                                    sliders[0].style.display = "block";
                                    this.props.onNavStyleChange(
                                        NAV_STYLE_FIXED
                                    );
                                }
                            }}
                        />
                    </div>
                ) : null}

                <div
                    className="gx-site-logo"
                    onClick={() => {
                        if (navStyle === NAV_STYLE_DRAWER) {
                            this.props.toggleCollapsedSideNav(!navCollapsed);
                        } else if (navStyle === NAV_STYLE_FIXED) {
                            // this.props.onNavStyleChange(
                            //     NAV_STYLE_MINI_SIDEBAR
                            // );
                            // const sliders = document.getElementsByClassName(
                            //     "sliders-wrapper"
                            // );
                            // console.log(sliders);
                            // sliders[0].style.display = "none";
                        } else if (
                            navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR
                        ) {
                            this.props.toggleCollapsedSideNav(!navCollapsed);
                        } else {
                            const sliders = document.getElementsByClassName(
                                "sliders-wrapper"
                            );
                            sliders[0].style.display = "block";
                            this.props.onNavStyleChange(NAV_STYLE_FIXED);
                        }
                    }}
                >
                    {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR &&
                    width >= TAB_SIZE ? (
                        <img alt="" src={require("assets/images/w-logo.png")} />
                    ) : themeType === THEME_TYPE_LITE ? (
                        <img
                            alt=""
                            src={require("assets/images/logo-white.png")}
                        />
                    ) : (
                        <div
                            className="logoText"
                            style={{ marginLeft: width < 992 ? -10 : -40 }}
                        >
                            {logoTextValue}
                            <sub>{logoTextValueSub}</sub>
                            <small>{logoTextValueSmall}</small>
                            <span className="logoText2">{logoTextValue2}</span>
                            <span
                                className={
                                    logoImageRef
                                        ? "logoTextImg invertImg"
                                        : "hideThisDiv"
                                }
                            >
                                <img alt="" src={logoImageRef} />
                            </span>
                        </div>
                    )}
                </div>
            </nav>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { navStyle, themeType, width, navCollapsed } = settings;
    return { navStyle, themeType, width, navCollapsed };
};

export default connect(mapStateToProps, {
    onNavStyleChange,
    toggleCollapsedSideNav
})(SidebarLogo);
