import React, { Component } from "react";
import { Layout, Modal, Button } from "antd";
import { Link } from "react-router-dom";
import {
    switchLanguage,
    toggleCollapsedSideNav
} from "../../appRedux/actions/Setting";
import Auxiliary from "util/Auxiliary";
import {
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_MINI_SIDEBAR,
    TAB_SIZE
} from "../../constants/ThemeSetting";
import { getFromStorage, setInStorage } from "localStorageService";
import { connect } from "react-redux";
import { iaText } from "config/iaText";
import { dataConfig } from "config/dataConfig";

const { Header } = Layout;
const emojiSDG = dataConfig.emojiSDG;
const confirm = Modal.info;

class Topbar extends Component {
    state = {
        searchText: "",
        openLabs: false,
        openTax: false,
        openIA: false,
        openSDG: false,
        openVeg: false,
    };

    componentDidMount() {
        this.props.toggleCollapsedSideNav(true);
    }

    openEqualityModal = () => {
        this.setState({
            openEquality: true
        });
    };
    goToEquality = () => {
        var userAction = getFromStorage("userAction") || getFromStorage("actions") || [""];
        userAction = userAction + '⚖️';
        setInStorage("userAction", userAction);

        this.openNewTab("https://InvestForEquality.com#gotoEq");
        this.onCloseEqualityModal();
    };
    onCloseEqualityModal = () => {
        this.setState({
            openEquality: false
        });
    };

    openSDGModal = () => {
        this.setState({
            openSDG: true
        });
    };
    goToSDG = () => {
        var userAction = getFromStorage("userAction") || getFromStorage("actions") || [""];
        userAction = userAction + '🇺🇳';
        setInStorage("userAction", userAction);

        this.openNewTab("https://CustomSDG.com#gotoSDG");
        this.onCloseSDGModal();
    };
    onCloseSDGModal = () => {
        this.setState({
            openSDG: false
        });
    };

    openVegModal = () => {
        this.setState({
            openVeg: true
        });
    };
    goToVeg = () => {
        var userAction = getFromStorage("userAction") || getFromStorage("actions") || [""];
        userAction = userAction + '🌱';
        setInStorage("userAction", userAction);

        this.openNewTab("https://vegemize.com#gotoVeg");
        this.onCloseVegModal();
    };
    onCloseVegModal = () => {
        this.setState({
            openVeg: false
        });
    };
    // openPerfModal = () => {
    //     this.setState({
    //         openPerf: true
    //     });
    // };
    // goToPerfLink = () => {
    //     this.openNewTab("https://impactlabs.com#perf");
    //     this.onClosePerfModal();
    // };
    // onClosePerfModal = () => {
    //     this.setState({
    //         openPerf: false
    //     });
    // };

    openLabsModal = () => {
        this.setState({
            openLabs: true
        });
    };
    goToImpactLabs = () => {
        var userAction = getFromStorage("userAction") || getFromStorage("actions") || [""];
        userAction = userAction + '🟦';
        setInStorage("userAction", userAction);

        this.openNewTab("https://impactlabs.com#gotoLabs");
        this.onCloseLabsModal();
    };
    onCloseLabsModal = () => {
        this.setState({
            openLabs: false
        });
    };

    openV1Modal = () => {
        this.setState({
            openV1: true
        });
    };
    goToV1 = () => {
        var userAction = getFromStorage("userAction") || getFromStorage("actions") || [""];
        userAction = userAction + '🔹';
        setInStorage("userAction", userAction);
        this.openNewTab("https://x.ImpactLabs.com#gotoCustomESG");
        this.onCloseV1Modal();
    };
    onCloseV1Modal = () => {
        this.setState({
            openV1: false
        });
    };

    openV2Modal = () => {
        this.setState({
            openV2: true
        });
    };
    goToV2 = () => {
        var userAction = getFromStorage("userAction") || getFromStorage("actions") || [""];
        userAction = userAction + '🌎';
        setInStorage("userAction", userAction);
        this.openNewTab("https://CustomESG.com#gotoCustomESG");
        this.onCloseV2Modal();
    };
    onCloseV2Modal = () => {
        this.setState({
            openV2: false
        });
    };

    openTaxModal = () => {
        this.setState({
            openTax: true
        });
    };
    goToTaxCalc = () => {
        var userAction = getFromStorage("userAction") || getFromStorage("actions") || [""];
        userAction = userAction + '🚕';
        setInStorage("userAction", userAction);
        this.openNewTab("https://tax.ImpactLabs.com#IMPACTx");
        this.onCloseTaxModal();
    };
    onCloseTaxModal = () => {
        this.setState({
            openTax: false
        });
    };

    openIAModal = () => {
        var userAction = getFromStorage("userAction") || getFromStorage("actions") || [""];
        console.log('userAction: ', userAction); 

        this.setState({
            openIA: true
        });
    };
    goToIA = () => {
        var userAction = getFromStorage("userAction") || getFromStorage("actions") || [""];
        userAction = userAction + '🔵';
        setInStorage("userAction", userAction);

        this.openNewTab("https://impactadvisor.com#gotoIA");
        this.onCloseIAModal();
    };
    onCloseIAModal = () => {
        this.setState({
            openIA: false
        });
    };

    openNewTab = url => {
        window.open(url, "_self");
        // const win = window.open(url, "_blank");
        // win.focus();
    };

    modalShowPerf = (ev) => {
        const textList = iaText();
        const text = textList["confirmModal.modalShowPerf.html"];
        const modalTitle = textList["confirmModal.modalShowPerf.title"];
        const iconUrl = textList["confirmModal.modalShowPerf.icon"];
        confirm({
            title: modalTitle,
            icon: <img src={iconUrl} alt="" />,
            maskClosable: true,
            content: (
                <div
                    dangerouslySetInnerHTML={{
                        __html: '<hr/>' + text
                    }}
                />
            ),
            onOk() {}
        });
    };

    render() {
        const { width, navCollapsed, navStyle } = this.props;
        const text = iaText();
        return (
            <Auxiliary>
                <Header>
                    {navStyle === NAV_STYLE_DRAWER ||
                    ((navStyle === NAV_STYLE_FIXED ||
                        navStyle === NAV_STYLE_MINI_SIDEBAR) &&
                        width < TAB_SIZE) ? (
                        <div className="gx-linebar gx-mr-3">
                            <i
                                className="gx-icon-btn icon icon-menu"
                                onClick={() => {
                                    this.props.toggleCollapsedSideNav(
                                        !navCollapsed
                                    );
                                }}
                            />
                        </div>
                    ) : null}
                    <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
                        {/* <img alt="" src={require("assets/images/w-logo.png")} /> */}
                    </Link>
                    <div className="gx-header-notifications partnerLogos">
                        <div className="createLogoContainer"
                            alt="TaxImpacts.com" title="TaxImpacts.com"
                            style={{background: '#3357ff'}}
                            onClick={this.openTaxModal}>
                            <div className="">&nbsp;
                                <strong><font color='silver'> Tax</font></strong>
                                {(window.innerWidth > 500) ? ' Impacts' : ''}<small><font color='silver'>{(window.innerWidth > 600) ? '.com' : ''}</font></small>&nbsp;&nbsp;
                            </div>
                        </div>
                        <div className="createLogoContainer" 
                            alt="ImpactAdvisor.com" title="ImpactAdvisor.com"
                            style={{background: '#3357ff'}}
                            onClick={this.openIAModal}>
                                <img className="" style={{padding:'0px 0px 2px 3px'}}
                                src='https://d3e1j90z5g3six.cloudfront.net/images/impact/IA_white_400x.png' alt="IA"/>
                                <strong>{(window.innerWidth > 375) ? ' IMPACT' : ' '}</strong>
                                <font color='silver'>ADVISOR</font><small>{(window.innerWidth > 500) ? '.com' : ''}</small>&nbsp;
                                <img src='https://d3e1j90z5g3six.cloudfront.net/images/other/A_BCorp_alpha_crop.png'
                                     className="bCorpImg"
                                    style={{padding:'0px 4px 3px 3px'}}
                                    alt="B Corp"/>
                        </div>
                        <div className="createLogoContainer"
                            alt="ImpactLabs.com" title="ImpactLabs.com"
                            style={{background: '#3357ff'}}
                            onClick={this.openLabsModal}>
                            <div className="">
                                <img src='https://d3e1j90z5g3six.cloudfront.net/images/impact/labs_logo_white-alpha400x.png'
                                    style={{padding:'0px 1px 2px 2px'}}
                                    alt="Impact Labs" width="20" height="auto"/>
                                <strong>{(window.innerWidth > 500) ? ' IMPACT' : (window.innerWidth > 413) ? ' IMPACT' : ' IMPACT'}</strong><font color='silver'> Labs</font><small>{(window.innerWidth > 500) ? '.com' : ''}</small>&nbsp;
                            </div>
                        </div>
                    </div>
                </Header>
                <Modal
                    onCancel={this.onCloseV2Modal}
                    visible={this.state.openV2}
                    footer={[
                        <Button key="back" onClick={this.onCloseV2Modal}>
                            Cancel
                        </Button>,
                        <Button key="forward" onClick={this.goToV2}
                            style={{background: '#3357ff', color: 'white'}}>
                            <span role="img" aria-label="Earth">🌎</span>ESG.ImpactLabs<small>.com</small>
                        </Button>
                    ]}>
                    <div className="headerModals"
                        dangerouslySetInnerHTML={{
                            __html: text["modal.v2"]
                        }}
                    />
                    <br />
                    <div onClick={ev => this.modalShowPerf( ev)}
                        dangerouslySetInnerHTML={{
                            __html: text["confirmModal.modalShowPerf.imageTap"]
                        }}
                    />
                </Modal>
                <Modal
                    onCancel={this.onCloseSDGModal}
                    visible={this.state.openSDG}
                    footer={[
                        <Button key="back" onClick={this.onCloseSDGModal}>
                            Cancel
                        </Button>,
                        <Button key="forward" onClick={this.goToSDG}
                            style={{background: '#5b92e5', color: 'white'}}>
                            {emojiSDG}&nbsp;SDG.ImpactLabs<small>.com</small>
                        </Button>
                    ]}
                >
                <div className="headerModals"
                    dangerouslySetInnerHTML={{
                        __html: text["modal.SDG"]
                    }}
                />
                </Modal>
                <Modal
                    onCancel={this.onCloseEqualityModal}
                    visible={this.state.openEquality}
                    footer={[
                        <Button key="back" onClick={this.onCloseEqualityModal}>
                            Cancel
                        </Button>,
                        <Button className="partnerLogos" key="forward" onClick={this.goToEquality}
                            style={{background: '#ff3a21', color: 'white'}}>
                            <img className="invertImg" src='https://d3e1j90z5g3six.cloudfront.net/images/icons/png/genderEq_400x.png' width="20" alt="gender equality symbol"/>  InvestForEquality<small>.com</small>
                        </Button>
                    ]}>
                    <div className="headerModals"
                        dangerouslySetInnerHTML={{
                            __html: text["modal.equality"]
                        }}
                    />
                    <br />
                    <div onClick={ev => this.modalShowPerf( ev)}
                        dangerouslySetInnerHTML={{
                            __html: text["confirmModal.modalShowPerf.imageTap"]
                        }}
                    />
                </Modal>
                <Modal
                    onCancel={this.onCloseVegModal}
                    visible={this.state.openVeg}
                    footer={[
                        <Button key="back" onClick={this.onCloseVegModal}>
                            Cancel
                        </Button>,
                        <Button key="forward" onClick={this.goToVeg}
                            style={{background: '#01A901', color: 'white'}}>
                            <span role="img" aria-label="Vegemize">🌱</span>Vegemize<small>.com</small>
                        </Button>
                    ]}>
                    <div className="headerModals"
                        dangerouslySetInnerHTML={{
                            __html: text["modal.veg"]
                        }}
                    />
                    <br />
                    <div onClick={ev => this.modalShowPerf( ev)}
                        dangerouslySetInnerHTML={{
                            __html: text["confirmModal.modalShowPerf.imageTap"]
                        }}
                    />
                </Modal>
                <Modal
                    onCancel={this.onCloseLabsModal}
                    visible={this.state.openLabs}
                    footer={[
                        <Button key="back" onClick={this.onCloseLabsModal}>
                            Cancel
                        </Button>,
                        <Button className="partnerLogos" key="forward"
                            onClick={this.goToImpactLabs}
                            style={{background: '#3357ff', color: 'white'}}>
                            <img src="https://d3e1j90z5g3six.cloudfront.net/favicons/labs/android-chrome-192x192.png"
                            width="20" alt="Impact Labs logo"/>&nbsp; IMPACT<font color='silver'> Labs</font><small>.com</small>
                        </Button>
                    ]}
                >
                <div className="headerModals"
                    dangerouslySetInnerHTML={{
                        __html: text["modal.labs"]
                    }}
                />
                </Modal>
                <Modal
                    onCancel={this.onCloseV1Modal}
                    visible={this.state.openV1}
                    footer={[
                        <Button key="back" onClick={this.onCloseV1Modal}>
                            Cancel
                        </Button>,
                        <Button className="partnerLogos" key="forward" onClick={this.goToV1}
                            style={{background: '#2c7ec0', color: 'white'}}>
                            <img src="https://d3e1j90z5g3six.cloudfront.net/favicons/labs2/android-chrome-192x192.png"
                            width="20" alt="Impact Labs logo"/>  ESG.ImpactLabs<small>.com</small>
                        </Button>
                    ]}
                >
                <div className="headerModals"
                    dangerouslySetInnerHTML={{
                        __html: text["modal.v1"]
                    }}
                />
                </Modal>
                <Modal
                    onCancel={this.onCloseTaxModal}
                    visible={this.state.openTax}
                    footer={[
                        <Button key="back" onClick={this.onCloseTaxModal}>
                            Cancel
                        </Button>,
                        <Button key="forward" onClick={this.goToTaxCalc}
                            style={{background: '#ee6e73', color: 'white'}}>
                            {(window.innerWidth > 360) ? 'tax.ImpactLabs' : 'TaxImpacts'}<small>.com</small>
                        </Button>
                    ]}
                >
                <div className="headerModals"
                    dangerouslySetInnerHTML={{
                        __html: text["modal.taxCalc"]
                    }}
                />
                </Modal>
                <Modal
                    onCancel={this.onCloseIAModal}
                    visible={this.state.openIA}
                    footer={[
                        <Button key="back" onClick={this.onCloseIAModal}>
                            Cancel
                        </Button>,
                        <Button key="forward" onClick={this.goToIA}
                            style={{background: '#3357ff', color: 'white'}}>
                            IMPACT<font color='silver'>ADVISOR</font><small>.com</small>
                        </Button>
                    ]}>
                    <div className="headerModals"
                        dangerouslySetInnerHTML={{
                            __html: text["modal.IA"]
                        }}/>
                </Modal>
            </Auxiliary>
        );
    }
}

const mapStateToProps = ({ settings, sidebar }) => {
    const { locale, navStyle, navCollapsed, width } = settings;
    return { locale, navStyle, navCollapsed, width, sidebar };
};

export default connect(
    mapStateToProps,
    { toggleCollapsedSideNav, switchLanguage }
)(Topbar);



                        // {(dataConfig.SDG|dataConfig.Veg|dataConfig.Equ) ?
                        //     <div className="createLogoContainer createLogoText"
                        //         style={{background: '#3357ff'}}
                        //         alt="CustomESG.com" title="CustomESG.com"
                        //         onClick={this.openV2Modal}>  
                        //         {(window.innerWidth > 1051) ? '🌎⚖️🛡 ESG.ImpactLabs ' : (window.innerWidth > 450) ? '🌎⚖️🛡 ESG ' : '🌎'} 
                        //     </div> : null
                        // }
                        // {dataConfig.Veg ? null :
                        //     <div className="createLogoContainer createLogoText"
                        //         style={{background: '#01A901'}}
                        //         alt="Vegemize.com" title="Vegemize.com"
                        //         onClick={this.openVegModal}>  
                        //         {(window.innerWidth > 960) ? '🌱 Vegan.ImpactLabs ' : (window.innerWidth > 760) ? '🌱 Vegan.ImpactLabs.com ' : (window.innerWidth > 550) ? '🌱 Veg ' : '🌱'} 
                        //     </div>
                        // }
                        // <div className="createLogoContainer createLogoText"
                        //     style={{background: '#5b92e5'}}
                        //     alt="CustomSDG.com" title="CustomSDG.com"
                        //     onClick={this.openSDGModal}>  <span role="img" aria-label="UN flag">{emojiSDG}</span>
                        //     {(window.innerWidth > 1051) ? ' SDG.ImpactLabs ' : (window.innerWidth > 800) ? ' Sust. Dev. Goals ' :(window.innerWidth > 500) ? ' SDGs ' : ''} 
                        // </div>
                        // <div className="createLogoContainer hideThisDiv" style={{background: '#2c7ec0'}}
                        //     alt="CustomESG.com" title="CustomESG.com"
                        //     onClick={this.openV1Modal}>
                        //         <img className="" style={{padding:'0px 1px 2px 2px'}}
                        //         src='https://d3e1j90z5g3six.cloudfront.net/images/impact/labs_logo_white-alpha400x.png' alt="CustomESG.com"/>
                        // </div>
                        // <div className="partnerLogoContainer">
                        //     <img src="https://d3e1j90z5g3six.cloudfront.net/favicons/tax/android-chrome-192x192.png"
                        //     alt="tax.ImpactLabs.com" title="tax.ImpactLabs.com"
                        //     onClick={this.openTaxModal}
                        // />
                        // </div>
                        // {dataConfig.SDG ? null :
                        //     <div className="createLogoContainer createLogoText"
                        //         style={{background: '#5b92e5'}}
                        //         alt="CustomSDG.com" title="CustomSDG.com"
                        //         onClick={this.openSDGModal}>  <span role="img" aria-label="UN flag">{emojiSDG}</span>
                        //         {(window.innerWidth > 1051) ? ' SDG.ImpactLabs ' : (window.innerWidth > 800) ? ' Sust. Dev. Goals ' :(window.innerWidth > 500) ? ' SDGs ' : ''} 
                        //     </div>
                        // }
                        // {dataConfig.Equ ? null :
                        //    <div className="createLogoContainer createLogoText"
                        //         style={{background: '#ff3a21'}}
                        //         alt="InvestForEquality.com" title="InvestForEquality.com"
                        //         onClick={this.openEqualityModal}>&nbsp;
                        //         <img src='https://d3e1j90z5g3six.cloudfront.net/images/icons/png/genderEq_400x.png'
                        //              className="invertImg"
                        //             style={{padding:'0px 1px 2px 1px'}}
                        //             alt="gender equality symbol" width="20" height="20"/>
                        //         {(window.innerWidth > 1111) ? '🌈 Equality.ImpactLabs ' : (window.innerWidth > 760) ? '🌈 Equality ' : (window.innerWidth > 550) ? '🌈 Eq ' : '🌈'} 
                        //     </div>
                        // }
                        // {(window.innerWidth > 375) ? '      ' : ''}&nbsp;