export default [
    {
        category: "sustainabilityCause",
        cause: "climateChange"
    },
    {
        category: "socialCause",
        cause: "women"
    },
    {
        category: "socialCause",
        cause: "labor"
    },
    {
        category: "socialCause",
        cause: "lgbt"
    },
    {
        category: "socialCause",
        cause: "diversity"
    },
    {
        category: "peaceCause",
        cause: "slavery"
    },
    {
        category: "peaceCause",
        cause: "animals"
    },
    {
        category: "foodCause",
        cause: "vegan"
    }
];
