import { handleActions } from "redux-actions";
import { flattenDeep } from "lodash";
import {
    GET_CAUSES_START,
    GET_CAUSES_SUCCESS,
    GET_CAUSES_FAIL,
    TICKERS_START,
    TICKERS_SUCCESS,
    TICKERS_FAIL,
    SET_CAUSE,
    SET_TICKER,
    IMPACT_POINTS_START,
    IMPACT_POINTS_SUCCESS,
    IMPACT_POINTS_FAIL,
    RESET_APP_DB_VERSION,
    RESET,
    SET_GEO_LOCATION
} from "./constants";
import { convertToImpactPoint } from "util/helpers";
import { getFromStorage, setInStorage, removeItem } from "localStorageService";
import { iaText } from "config/iaText";
import { dataConfig } from "config/dataConfig";
import { demo, equ, pope, initialCauses, psych, sdg, veg, vj } from "./causes";
import { convertToStep } from "util/helpers";


console.log('🔥', dataConfig.emoji, dataConfig.codeVersion);
// unused: const dbKey = getFromStorage('db') || dataConfig.dbKey;
const clientKey = getFromStorage('clientKey') || dataConfig.clientKey;
console.log('🔥🔥 getFromStorage(db) ', getFromStorage('db') , dataConfig.dbKey);
console.log('🔥🔥 getFromStorage(clientKey) ', getFromStorage('clientKey') , dataConfig.clientKey);

const returnsFromLocalStorage = getFromStorage("returns") || [];
const selectedCausesFromLocalStorage = getFromStorage("selectedCauses") || {};
const currentCauseFromLocalStorage = getFromStorage("currentCause") || {};
const tickerCausesFromLocalStorage = getFromStorage("tickerCauses") || {};

const anchor = window.location.hash.split("/")[0];
const defaultCauseID = iaText()["defaultCauseID"];
console.log("anchor: ", anchor);

const initialState = {
    geo_ip: {
        // ip: "",
        city: "▫️",
        // region: "",
        // areaCode: "",
        // dmaCode: "",
        // currencyCode: "",
        // regionCode: "",
        // countryCode: "",
        // country: "",
        // continentCode: "",
        // latitude: "",
        // longitude: "",
        // timezone: "",
        anchor: anchor.replace("portfolio", ""),

        appCodeName: navigator.appCodeName,
        appName: navigator.appName,
        language: navigator.language,
        onLine: navigator.onLine,
        navPlatform: dataConfig.navPlatform,
        vendor: navigator.vendor,
        userAgent: navigator.userAgent,
        device: /Mobi/.test(navigator.userAgent) ? "Mobile" : "Desktop",
        windowInnerWidthHeight: window.innerWidth + "x" + window.innerHeight,
        websiteHref: window.location.href,
        websiteDomain: window.location.hostname.replace("www.", ""),
        websitePath: window.location.pathname
    },
    causes: [],
    isLoading: false,
    isSavingCauses: false,
    resetApp: false,
    globalReset: false,
    errorMessage: "",
    selectedCauses: selectedCausesFromLocalStorage,
    excluded: [],
    included: [],
    returns: returnsFromLocalStorage,
    currentCause: currentCauseFromLocalStorage,
    tickerCauses: tickerCausesFromLocalStorage || {},
    popularCauses: [
        {
            causeCategory: iaText()[
                "cause." + defaultCauseID + ".categoryLookup"
            ],
            causeEnabled: true,
            causeId: defaultCauseID,
            causeLabel: iaText()["cause." + defaultCauseID + ".title"],
            causeVisible: true,
            causeWeight: 75
        }
    ],
    benchmark: {},
    hasTickers: false,
    hasImpactPoints: false,
    presets: {}
};

function recursiveUpdate(causes, selectedItems, categories) {
    const clonedCauses = causes;

    Object.keys(selectedItems).forEach(key => {
        const selectedItemCategory = selectedItems[key].causeCategory;
        const categoryIndex = categories.find(
            category => category.name === selectedItemCategory
        );
        clonedCauses[categoryIndex.id][key].causeWeight =
            selectedItems[key].causeWeight;
    });
    return clonedCauses;
}

const collapsedCategories = [];

function reorderCauses(categories, causes) {
    let clonedCauses = [];

    categories.forEach((cat, index) => {
        Object.keys(causes).forEach(key => {
            if (key === cat.name) {
                if (cat.collapsed) {
                    collapsedCategories.push(cat.name);
                }
                clonedCauses[cat.id] = causes[key];
            }
        });
    });
    return clonedCauses;
}

function findPopularCauses(allCauses) {
    let causes;
    causes = clientKey; 

    if (clientKey === 'SDG') {
        causes = sdg;
    } else if (clientKey === 'Equ') {
        causes = equ;
    } else if (clientKey === 'Veg') {
        causes = veg;
    } else if (clientKey === 'Pope') {
        causes = pope;
    } else if (clientKey === 'Psych') {
        causes = psych;
    } else if (clientKey === 'VJ') {
        causes = vj;
    } else if (clientKey === 'Demo') {
        causes = demo;
    } else {
        causes = initialCauses;
    }
    return causes.map(cause => allCauses[cause.category][cause.cause]);
}

function presetCauses(allCauses = {}) {
    return Object.values(allCauses).map(causes => {
        return Object.values(causes).filter(
            cause => cause.causeVisible && cause.preset
        );
    });
}

function transformPresetsToStep(presets) {
    const presetsToStepObject = {};
    presets.forEach(preset => {
        const { causeId, causeWeight, causeCategory } = preset;
        presetsToStepObject[causeId] = {
            step: convertToStep(causeWeight),
            category: causeCategory
        };
    });

    return presetsToStepObject;
}

export default handleActions(
    {
        [GET_CAUSES_START]: state => ({
            ...state,
            isLoading: true
        }),
        [SET_GEO_LOCATION]: (state, payload) => {
            const geo_ip = {
                ip: payload.geo_ip.geoplugin_request,
                city: payload.geo_ip.geoplugin_city.replace('San Francisco', 'SF'),
                region: payload.geo_ip.geoplugin_region,
                areaCode: payload.geo_ip.geoplugin_areaCode,
                dmaCode: payload.geo_ip.geoplugin_dmaCode,
                currencyCode: payload.geo_ip.geoplugin_currencyCode,
                regionCode: payload.geo_ip.geoplugin_regionCode,
                countryCode: payload.geo_ip.geoplugin_countryCode,
                country: payload.geo_ip.geoplugin_countryName,
                continentCode: payload.geo_ip.geoplugin_continentCode,
                latitude: payload.geo_ip.geoplugin_latitude,
                longitude: payload.geo_ip.geoplugin_longitude,
                timeZone: payload.geo_ip.geoplugin_timezone
            };
            //console.log('🔑🔑🔑🔑 geo_ip:', geo_ip);
            return {
                ...state,
                geo_ip: {
                    ...state.geo_ip,
                    ...geo_ip
                }
            };
        },
        [GET_CAUSES_SUCCESS]: (state, payload) => {
            const selectPopularCauses = findPopularCauses(payload.causes);
            const causes = reorderCauses(payload.categories, payload.causes);
            const presets = flattenDeep(presetCauses(payload.causes));
            const presetsToSteps = transformPresetsToStep(presets);
            return {
                ...state,
                causes: recursiveUpdate(
                    causes,
                    state.selectedCauses,
                    payload.categories
                ),
                collapsedCategories,
                popularCauses: selectPopularCauses,
                isLoading: false,
                categories: payload.categories,
                presets,
                presetsToSteps
            };
        },
        [GET_CAUSES_FAIL]: (state, payload) => ({
            ...state,
            errorMessage: payload,
            isLoading: false
        }),
        [TICKERS_START]: state => ({
            ...state,
            isLoading: true,
            hasTickers: false,
            globalReset: false
        }),
        [TICKERS_SUCCESS]: (state, data) => {
            const { returns, tickers, benchmark } = data.payload;
            setInStorage("returns", returns);
            return {
                ...state,
                tickers,
                returns,
                benchmark,
                isLoading: false,
                hasTickers: true,
                sector_breakdown: benchmark.Benchmark.sector_breakdown
            };
        },
        [TICKERS_FAIL]: (state, payload) => ({
            ...state,
            errorMessage: payload,
            isLoading: false
        }),

        [IMPACT_POINTS_START]: state => ({
            ...state,
            hasImpactPoints: false,
            globalReset: false
        }),
        [IMPACT_POINTS_SUCCESS]: (state, data) => {
            return {
                ...state,
                excluded: data.payload.excluded,
                included: data.payload.included,
                hasImpactPoints: true
            };
        },
        [IMPACT_POINTS_FAIL]: (state, payload) => ({
            ...state,
            errorMessage: payload,
            isLoading: false
        }),

        [SET_CAUSE]: (state, payload) => {
            const { cause } = payload;
            const selectedCauses = {
                ...state.selectedCauses,
                [cause.causeId]: cause
            };
            setInStorage("selectedCauses", selectedCauses);
            setInStorage("currentCause", cause);
            return {
                ...state,
                currentCause: cause,
                selectedCauses
            };
        },

        [SET_TICKER]: (state, payload) => {
            const { cause } = payload;
            const tickerCauses = {
                ...state.tickerCauses,
                [cause.causeId]: convertToImpactPoint(cause.causeWeight)
            };
            setInStorage("tickerCauses", tickerCauses);

            return {
                ...state,
                tickerCauses
            };
        },

        [RESET_APP_DB_VERSION]: state => ({
            ...state,
            resetApp: true
        }),

        [RESET]: (state, payload) => {
            removeItem("tickerCauses");
            removeItem("currentCause");
            removeItem("selectedCauses");
            removeItem("returns");
            removeItem("steps");
            removeItem("included");
            removeItem("excluded");
            removeItem("presetsLoaded");
            //debugger;
            removeItem("modalOpened");
            // localStorage.clear();
            window.location = window.location.origin;

            return {
                ...state,
                causes: [],
                isLoading: false,
                isSavingCauses: false,
                resetApp: false,
                globalReset: false,
                errorMessage: "",
                selectedCauses: {},
                popularCauses: [
                    {
                        causeCategory: iaText()[
                            "cause." + defaultCauseID + ".categoryLookup"
                        ],
                        causeEnabled: true,
                        causeId: defaultCauseID,
                        causeLabel: iaText()[
                            "cause." + defaultCauseID + ".title"
                        ],
                        causeVisible: true,
                        causeWeight: 75
                    }
                ],
                excluded: [],
                included: [],
                returns: [],
                currentCause: {},
                tickerCauses: {},
                tickers: [],
                presets: {},
                benchmark: {},
                sector_breakdown: {}
            };
        }
    },
    initialState
);
