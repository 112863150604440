export default [
    {
        category: "sustainabilityCause",
        cause: "climateChange"
    },
    {
        category: "socialCause",
        cause: "women"
    },
    {
        category: "artCause",
        cause: "education"
    },
    {
        category: "peaceCause",
        cause: "slavery"
    },
    {
        category: "socialCause",
        cause: "poverty"
    },
    {
        category: "healthCause",
        cause: "health"
    },
    {
        category: "sustainabilityCause",
        cause: "altEnergy"
    },
    {
        category: "sustainabilityCause",
        cause: "waterConservation"
    },
    {
        category: "socialCause",
        cause: "humanRights"
    }
];
