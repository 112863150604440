export const GET_ALL_CAUSES_START = 'GET_ALL_CAUSES_START';
export const GET_ALL_CAUSES_SUCCESS = 'GET_ALL_CAUSES_SUCCESS';
export const GET_ALL_CAUSES_FAIL = 'GET_ALL_CAUSES_FAIL';

export const GET_DEFAULT_CAT_START = 'GET_DEFAULT_CAT_START';
export const GET_DEFAULT_CAT_SUCCESS = 'GET_DEFAULT_CAT_SUCCESS';
export const GET_DEFAULT_CAT_FAIL = 'GET_DEFAULT_CAT_FAIL';

export const GET_CUSTOM_CAT_START = 'GET_CUSTOM_CAT_START';
export const GET_CUSTOM_CAT_SUCCESS = 'GET_CUSTOM_CAT_SUCCESS';
export const GET_CUSTOM_CAT_FAIL = 'GET_CUSTOM_CAT_FAIL';

export const GET_DEFAULT_CAUSES_START = 'GET_DEFAULT_CAUSES_START';
export const GET_DEFAULT_CAUSES_SUCCESS = 'GET_DEFAULT_CAUSES_SUCCESS';
export const GET_DEFAULT_CAUSES_FAIL = 'GET_DEFAULT_CAUSES_FAIL';

export const GET_SETTINGS_START = 'GET_SETTINGS_START';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAIL = 'GET_SETTINGS_FAIL';

export const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY';

export const UPDATE_CAUSES_START = 'UPDATE_CAUSES_START';
export const UPDATE_CAUSES_SUCCESS = 'UPDATE_CAUSES_SUCCESS';
export const UPDATE_CAUSES_FAIL = 'UPDATE_CAUSES_FAIL';