export const getSelectedCauses = selectedCauses => {
    const causes = {};
    Object.keys(selectedCauses).forEach(key => {
        const secondKeys = Object.keys(selectedCauses[key]);
        secondKeys.forEach(k => {
            causes[k] = selectedCauses[key][k].causeWeight;
        });
    });
    return causes;
};

export const mapCauses = (firebaseModel, customMapper) => {
    const newModel = {};
    customMapper.forEach(map => {
        newModel[map] = firebaseModel[map];
    });
    return newModel;
};

export const convertToImpactPoint = weight => {
    switch (weight) {
        case 25:
            return -1;
        case 50:
            return 0;
        case 75:
            return 1;
        case 100:
            return 2;
        default:
            return -2;
    }
};

export const convertFromImpactPoint = weight => {
    switch (weight) {
        case -1:
            return 25;
        case 0:
            return 50;
        case 1:
            return 75;
        case 2:
            return 100;
        default:
            return 0;
    }
};

export const convertToStep = weight => {
    switch (weight) {
        case 25:
            return 1;
        case 50:
            return 2;
        case 75:
            return 3;
        case 100:
            return 4;
        default:
            return 0;
    }
};

export const convertFromStep = weight => {
    switch (weight) {
        case 1:
            return 25;
        case 2:
            return 50;
        case 3:
            return 75;
        case 4:
            return 100;
        default:
            return 0;
    }
};
