import { dataConfig } from "config/dataConfig";

// const siteColor =
//     /(sdg|xxx)/.test(window.location.hostname) ? '#5b92e5' :
//     /(veg|xxx)/.test(window.location.hostname) ? '#01A901' :
//     /(equality|xxx)/.test(window.location.hostname) ? '#ff3a21' :
//     /(pope|francis|xxx)/.test(window.location.hostname) ? '#911eb4' :
//     /(esg|xxx)/.test(window.location.hostname) ? '#3357ff' : '#3357ff';
// const modalVideoSize = window.innerWidth < 361 ? "width='220' height='124'" :
//     window.innerWidth < 415 ? "width='260' height='146'" :
//     window.innerWidth < 769 ? "width='360' height='204'" :
//     "width='460' height='259'"; // for YT videos if we add later


export const iaText = () => {
    return {
        // Company Info:
        "company.name.text": "Impact Labs",

        // reset/default: set an active cause or reset
        "defaultCauseID": "climateChange",

        // use to look-up causeCategory:
        "cause.climateChange.categoryLookup": "sustainabilityCause",
        "cause.lgbt.categoryLookup": "socialCause",
        "cause.women.categoryLookup": "socialCause",

        // "company.name.text": "Impact Labs",
        "company.availableCauses.number": "50+",
        "company.rankedCompanies.number": "950+",
        "company.guestEmail.domain": "@ImpactLabs.com",

        "app.name.text": "", //'Public',
        "app.version.fullnumber": "", //'v1.3',
        "app.subtitle1.text": "Invest Intentionally",
        "app.subtitle2.text":
            "Translating your passions into financial success",

        "video.subtitle2.text":
            "Translating your passions into financial success",
        "video.src":
            "https://player.vimeo.com/video/133245453?title=0&byline=0&portrait=0",

        // Return Info:
        "indexReturn.russell3000.oneYear": "+0.5%",
        "indexReturn.russell3000.threeYear": "+51%",
        "indexReturn.russell3000.return3": "+0.5%",
        "indexReturn.russell3000.return2": "+12.6%",
        "indexReturn.russell3000.return1": "+33.6%",
        "indexReturn.russell3000.2015": "+ 0.5%",
        "indexReturn.russell3000.2014": "+12.6%",
        "indexReturn.russell3000.2013": "+33.6%",
        "indexReturn.russell3000.2012": "+16.4%",

        "profiles.label.return3": "2015",
        "profiles.label.return2": "2014",
        "profiles.label.return1": "2013",

        "returns.label.return3": "2013",
        "returns.label.return2": "2014",
        "returns.label.return1": "2015",

        // Pop-up text if user taps on the return #s.
        "profiles.info.return3": "Returns for the period 12/31/2014-12/31/2015",
        "profiles.info.return2": "Returns for the period 12/31/2013-12/31/2014",
        "profiles.info.return1": "Returns for the period 12/31/2012-12/31/2013",

        // Profile (Preset) return data examples; we can also store this differently.
        // For Profiles, there isn't the need for dynamic updating like we'll do when we are in the Causes.

        // default setting until a # is calculated
        "returns.russell3000.return1": "+  0.5%",
        "returns.russell3000.return2": "+12.6%",
        "returns.russell3000.return3": "+33.6%",

        "profile.russell3000.return1": "+ 0.5%",
        "profile.russell3000.return2": "+12.6%",
        "profile.russell3000.return3": "+33.6%",

        "profile.africanAm.return1": "+ 0.5%",
        "profile.africanAm.return2": "+12.5%",
        "profile.africanAm.return3": "+30.5%",

        "profile.eastIndian.return1": "+ 0.5%",
        "profile.eastIndian.return2": "+12.5%",
        "profile.eastIndian.return3": "+30.5%",

        "profile.nativeAm.return1": "+ 0.5%",
        "profile.nativeAm.return2": "+12.5%",
        "profile.nativeAm.return3": "+30.5%",

        "profile.latinAm.return1": "+ 0.5%",
        "profile.latinAm.return2": "+12.5%",
        "profile.latinAm.return3": "+30.5%",

        "profile.vegan.return1": "+ 0.5%",
        "profile.vegan.return2": "+12.5%",
        "profile.vegan.return3": "+30.5%",

        "profile.trump.return1": "+ 0.5%",
        "profile.trump.return2": "+12.5%",
        "profile.trump.return3": "+30.5%",

        "profile.clinton.return1": "+ 0.5%",
        "profile.clinton.return2": "+12.5%",
        "profile.clinton.return3": "+30.5%",

        "profile.green.return1": "+ 0.5%",
        "profile.green.return2": "+12.5%",
        "profile.green.return3": "+30.5%",

        "profile.maxSustainability.return1": "+ 0.5%",
        "profile.maxSustainability.return2": "+12.5%",
        "profile.maxSustainability.return3": "+30.5%",

        "profile.breastCancer.return1": "+ 0.5%",
        "profile.breastCancer.return2": "+12.5%",
        "profile.breastCancer.return3": "+30.5%",

        "profile.children.return1": "+ 0.5%",
        "profile.children.return2": "+12.5%",
        "profile.children.return3": "+30.5%",

        // Other Logos

        "image.labsIcon.rounded":
            "https://d3e1j90z5g3six.cloudfront.net/images/impact/labsLogo.png",

        "company.SFDC.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/SFDC.png",
        "company.T.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/other/AT%26T_200x.png",
        "company.AAPL2.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/other/AAPL_300x.jpg",
        "company.AAPL.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/other/AAPL_280x.jpg",
        "company.PM.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/PM.png",

        // GIFs
        "sliderGIF.climateChange":
            "https://d3e1j90z5g3six.cloudfront.net/images/gifs/slidersClimateChange_color.gif",
        "sliderGIF.women":
            "https://d3e1j90z5g3six.cloudfront.net/images/gifs/slidersGenderEquality.gif",
        "sliderGIF.sugar":
            "https://d3e1j90z5g3six.cloudfront.net/images/gifs/slidersSugar_color.gif",
        "sliderGIF.tobacco":
            "https://d3e1j90z5g3six.cloudfront.net/images/gifs/slidersTobacco_color.gif",
        "sliderGIF.weapons":
            "https://d3e1j90z5g3six.cloudfront.net/images/gifs/slidersWeapons_blue.gif",

        // Profiles- names and icons
        "profiles.skip.to.causes": "Skip to causes",
        "profiles.select.profile.causes": "Select cause",

        "profiles.catholic.group.card": "",
        "cause.description.religionCauseCard": "",

        // Other Profiles
        "profile.vegan.title": "Vegan / Vegetarian",
        "profile.vegan.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_147303.png",
        "profile.vegan.description1": "",
        "profile.vegan.description2":
            "This preset includes causes such Vegan/Vegetarian, Animal Rights and Humane Animal Farming",

        // Ethnicity
        "profile.africanAm.title": "African American",
        "profile.africanAm.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_248.png",
        "profile.eastIndian.title": "East Indian",
        "profile.eastIndian.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_248.png",
        "profile.latinAm.title": "Latin American",
        "profile.latinAm.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_248.png",
        "profile.nativeAm.title": "Native American",
        "profile.nativeAm.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_248.png",

        // Environment Profiles
        "profileGroup.sustainability.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_147211.png",
        "profile.green.title": "Greenpeace",
        "profile.green.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_16622.png",
        "profile.maxSustainability.title": "Maximum Sustainability",
        "profile.maxSustainability.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_58979.png",

        // Women Profiles
        "profile.womenElevation.title": "Elevating Women",
        "profile.womenElevation.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_169612.png",
        "profile.womenElevation2.title": "Women Elevation2",
        "profile.womenElevation2.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_169612.png",
        "profile.children.title": "Green & Healthy Living",
        "profile.children.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_147211.png",
        "profile.breastCancer.title": "Women & Health",
        "profile.breastCancer.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_69957.png",

        // Religion/Spirituality
        "profileGroup.religion.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_122248.png",

        "profile.catholicPope.title": "Catholic: Pope Francis",
        "profile.catholicPope.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_13687_youngPope.png",
        "profile.catholic.title": "Catholic",
        "profile.catholic.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_11571.png",

        "profile.scientology.title": "Scientologist",
        "profile.scientology.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_200409.png",

        // Political Profiles
        "profile.sanders.title": "Sanders",
        "profile.sanders.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_240644_sanders.png",
        "profile.sanders.description1":
            "How's that 2020 hindsight?  Let's do it right next time -- we're still feeling the Burn!",
        "profile.sanders.description2": "🔥🔥",

        "profile.clinton.title": "Clinton",
        "profile.clinton.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_215276_clinton.png",
        "profile.clinton.description1": "",
        "profile.clinton.description2": "",

        "profile.democrat.title": "Democrat",
        "profile.democrat.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_26092.png",
        "profile.democrat.description1": "",
        "profile.democrat.description2": "",

        "profile.independent.title": "Independent",
        "profile.independent.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_59542_indep.png",
        "profile.independent.description1": "",
        "profile.independent.description2": "",

        "profile.libertarian.title": "Libertarian",
        "profile.libertarian.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_96292.png",
        "profile.libertarian.description1": "",
        "profile.libertarian.description2": "",

        "profile.republican.title": "Republican",
        "profile.republican.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_26091.png",
        "profile.republican.description1": "",
        "profile.republican.description2": "",

        "profile.republicanRight.title": "Republican-Right",
        "profile.republicanRight.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_26091.png",
        "profile.republicanRight.description1": "",
        "profile.republicanRight.description2": "",

        "profile.teaParty.title": "Tea Party",
        "profile.teaParty.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_171714.png",
        "profile.teaParty.description1": "",
        "profile.teaParty.description2": "",

        "profile.trump.title": "Trump",
        "profile.trump.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/svg/noun_191710.svg",
        "profile.trump.description1": "🇺🇸 Keep investing great!    ",
        "profile.trump.description2": "🇺🇸",

        // Cause Groups. *** not used!!!!
        "causeGroup.popularCauses.title": "Most Popular Causes",
        "causeGroup.artCauses.title": "xxThe Arts, Culture & Science",
        "causeGroup.corpCauses.title": "Corporate Governance, etc.",
        "causeGroup.drugCauses.title": "Drugs and/or Vice",
        "causeGroup.foodCauses.title": "Food Quality Causes",
        "causeGroup.healthCauses.title": "Health-Related Causes",
        "causeGroup.socialCauses.title": "Social / Equality Causes",
        "causeGroup.peaceCauses.title": "Peace & Freedom Causes",
        "causeGroup.sustainabilityCauses.title": "Environment / Sustainability",

        // Causes
        "cause.placeHolder.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.aids.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.aids.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_2219.png",
        "cause.aids.title": "AIDS",
        "cause.aids.XXX": "",
        "cause.aids.YYY": "",
        "cause.aids.ZZZ": "",
        "cause.alcohol.company1logo": "noun-146707",
        "cause.alcohol.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_146707.png",
        "cause.alcohol.title": "Alcohol",
        "cause.alcohol.XXX": "",
        "cause.alcohol.YYY": "",
        "cause.alcohol.ZZZ": "",
        "cause.altEnergy.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/TSLA.png",
        "cause.altEnergy.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_81668.png",
        "cause.altEnergy.title": "Alternative Energy",
        "cause.altEnergy.XXX": "",
        "cause.altEnergy.YYY": "",
        "cause.altEnergy.ZZZ": "",
        "cause.alzheimers.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/HCN.png",
        "cause.alzheimers.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_38473.png",
        "cause.alzheimers.title": "Alzheimer's Disease",
        "cause.alzheimers.XXX": "",
        "cause.alzheimers.YYY": "",
        "cause.alzheimers.ZZZ": "",
        "cause.animals.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/CL.png",
        "cause.animals.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_13421.png",
        "cause.animals.title": "Animal Welfare",
        "cause.animals.XXX": "",
        "cause.animals.YYY": "",
        "cause.animals.ZZZ": "",
        "cause.arts.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_13644.png",
        "cause.arts.desc1":
            "The private sector has historically played a significant role in promoting the Arts.",
        "cause.arts.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_13644.png",
        "cause.arts.title": "Creative Arts",
        "cause.arts.XXX": "",
        "cause.arts.YYY": "",
        "cause.arts.ZZZ": "",
        "cause.bCorp.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/ETSY.png",
        "cause.bCorp.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_178506.png",
        "cause.bCorp.title": "B Corporations",
        "cause.bCorp.XXX": "",
        "cause.bCorp.YYY": "",
        "cause.bCorp.ZZZ": "",
        "cause.cancer.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.cancer.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_15049_Cancer.png",
        "cause.cancer.title": "Fight Cancer",
        "cause.cancer.XXX": "",
        "cause.cancer.YYY": "",
        "cause.cancer.ZZZ": "",
        "cause.cancerBreast.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.cancerBreast.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_2219.png",
        "cause.cancerBreast.title": "Breast Health",
        "cause.cancerBreast.XXX": "",
        "cause.cancerBreast.YYY": "",
        "cause.cancerBreast.ZZZ": "",
        "cause.carbonNeutral.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/HSBC.png",
        "cause.carbonNeutral.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_42474.png",
        "cause.carbonNeutral.title": "Low Carbon",
        // "cause.carbonNeutral.title": "Fossil Fuels",
        "cause.carbonNeutral.XXX": "",
        "cause.carbonNeutral.YYY": "",
        "cause.carbonNeutral.ZZZ": "",
        "cause.children.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.children.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_189055.png",
        "cause.children.title": "Children",
        "cause.children.XXX": "",
        "cause.children.YYY": "",
        "cause.children.ZZZ": "",
        "cause.circularEconomy.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.circularEconomy.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_96758.png",
        "cause.circularEconomy.title": "Circular Economy",
        "cause.circularEconomy.XXX": "",
        "cause.circularEconomy.YYY": "",
        "cause.circularEconomy.ZZZ": "",
        "cause.climateChange.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/GOOGL.png",
        "cause.climateChange.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_165038.png",
        "cause.climateChange.title": "Climate Urgency",
        "cause.climateChange.XXX": "",
        "cause.climateChange.YYY": "",
        "cause.climateChange.ZZZ": "",
        "cause.coal.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.coal.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_40461.png",
        "cause.coal.title": "Coal",
        "cause.coal.XXX": "",
        "cause.coal.YYY": "",
        "cause.coal.ZZZ": "",
        "cause.culture.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.culture.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_21056.png",
        "cause.culture.title": "Culture",
        "cause.culture.XXX": "",
        "cause.culture.YYY": "",
        "cause.culture.ZZZ": "",
        "cause.dairy.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/DF.png",
        "cause.dairy.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_10869.png",
        "cause.dairy.title": "Dairy",
        "cause.dairy.XXX": "",
        "cause.dairy.YYY": "",
        "cause.dairy.ZZZ": "",
        "cause.diabetes.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.diabetes.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_577.png",
        "cause.diabetes.title": "Diabetes",
        "cause.diabetes.XXX": "",
        "cause.diabetes.YYY": "",
        "cause.diabetes.ZZZ": "",
        "cause.causeDisabled.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.causeDisabled.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_170802.png",
        "cause.causeDisabled.title": "Disabled",
        "cause.causeDisabled.XXX": "",
        "cause.causeDisabled.YYY": "",
        "cause.causeDisabled.ZZZ": "",
        "cause.diversity.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.diversity.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_51630.png",
        "cause.diversity.title": "Diversity",
        "cause.diversity.XXX": "",
        "cause.diversity.YYY": "",
        "cause.diversity.ZZZ": "",
        "cause.divestment.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/HAL.png",
        "cause.divestment.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_148944.png",
        "cause.divestment.title": "Divestment: Anti-Fossil Fuels",
        "cause.divestment.XXX": "",
        "cause.divestment.YYY": "",
        "cause.divestment.ZZZ": "",
        "cause.drugs.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_12822r.png",
        "cause.drugs.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_52053.png",
        "cause.drugs.title": "Drugs in General",
        "cause.drugs.XXX": "",
        "cause.drugs.YYY": "",
        "cause.drugs.ZZZ": "",
        "cause.education.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.education.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_88629.png",
        "cause.education.title": "Education & Literacy",
        "cause.education.XXX": "",
        "cause.education.YYY": "",
        "cause.education.ZZZ": "",
        "cause.energyCons.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.energyCons.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_144931.png",
        "cause.energyCons.title": "Energy Conservation",
        "cause.energyCons.XXX": "",
        "cause.energyCons.YYY": "",
        "cause.energyCons.ZZZ": "",
        "cause.fashionStyle.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.fashionStyle.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_119511.png",
        "cause.fashionStyle.title": "Fashion & Style",
        "cause.fashionStyle.XXX": "",
        "cause.fashionStyle.YYY": "",
        "cause.fashionStyle.ZZZ": "",
        "cause.fastFood.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/MCD.png",
        "cause.fastFood.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_89010.png",
        "cause.fastFood.title": "Fast Food",
        "cause.fastFood.XXX": "",
        "cause.fastFood.YYY": "",
        "cause.fastFood.ZZZ": "",
        "cause.fightISIS.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/HAL.png",
        "cause.fightISIS.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_171714.png",
        "cause.fightISIS.title": "Fight ISIS",
        "cause.fightISIS.XXX": "",
        "cause.fightISIS.YYY": "",
        "cause.fightISIS.ZZZ": "",
        "cause.fightTerrorism.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/HAL.png",
        "cause.fightTerrorism.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_90802.png",
        "cause.fightTerrorism.title": "Fight Terrorism",
        "cause.fightTerrorism.XXX": "",
        "cause.fightTerrorism.YYY": "",
        "cause.fightTerrorism.ZZZ": "",
        "cause.fossilFuels.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/XOM.png",
        "cause.fossilFuels.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_42474.png",
        "cause.fossilFuels.title": "Fossil Fuels",
        "cause.fossilFuels.XXX": "",
        "cause.fossilFuels.YYY": "",
        "cause.fossilFuels.ZZZ": "",
        "cause.fracking.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.fracking.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_147688.png",
        "cause.fracking.title": "Fracking",
        "cause.fracking.XXX": "",
        "cause.fracking.YYY": "",
        "cause.fracking.ZZZ": "",
        "cause.gambling.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/LVS.png",
        "cause.gambling.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_15893.png",
        "cause.gambling.title": "Gambling",
        "cause.gambling.XXX": "",
        "cause.gambling.YYY": "",
        "cause.gambling.ZZZ": "",
        "cause.girls.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.girls.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_162138.png",
        "cause.girls.title": "Pro-Girls",
        "cause.girls.XXX": "",
        "cause.girls.YYY": "",
        "cause.girls.ZZZ": "",
        "cause.gluten.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/GIS.png",
        "cause.gluten.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_178915.png",
        "cause.gluten.title": "Pro-Gluten",
        "cause.gluten.XXX": "",
        "cause.gluten.YYY": "",
        "cause.gluten.ZZZ": "",
        "cause.governance.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/WeWork.png",
        "cause.governance.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_12741.png",
        "cause.governance.title": "Corporate Governance",
        "cause.governance.XXX": "",
        "cause.governance.YYY": "",
        "cause.governance.ZZZ": "",
        "cause.gunControl.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/SWHC.png",
        "cause.gunControl.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_61803.png",
        "cause.gunControl.title": "Gun Control",
        "cause.gunControl.XXX": "",
        "cause.gunControl.YYY": "",
        "cause.gunControl.ZZZ": "",
        "cause.health.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.health.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_32411.png",
        "cause.health.title": "Healthy Lifestyle",
        "cause.health.XXX": "",
        "cause.health.YYY": "",
        "cause.health.ZZZ": "",
        "cause.heartDisease.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.heartDisease.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_65178.png",
        "cause.heartDisease.title": "Heart Disease",
        "cause.heartDisease.XXX": "",
        "cause.heartDisease.YYY": "",
        "cause.heartDisease.ZZZ": "",
        "cause.humaneAnimalFarming.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/HRL.png",
        "cause.humaneAnimalFarming.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_58367.png",
        "cause.humaneAnimalFarming.title": "Humane Animal Farming",
        "cause.humaneAnimalFarming.XXX": "",
        "cause.humaneAnimalFarming.YYY": "",
        "cause.humaneAnimalFarming.ZZZ": "",
        "cause.humanRights.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.humanRights.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_248.png",
        "cause.humanRights.title": "Human Rights",
        "cause.humanRights.XXX": "",
        "cause.humanRights.YYY": "",
        "cause.humanRights.ZZZ": "",
        "cause.immigration.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/FB.png",
        "cause.immigration.desc1":
            "Companies recognized for fair labor practices. Also recognized are companies scoring highly for work-life balance, such as Colgate-Palmolive and H&R Block.",
        "cause.immigration.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_31.png",
        "cause.immigration.title": "Immigration",
        "cause.immigration.XXX": "",
        "cause.immigration.YYY": "",
        "cause.immigration.ZZZ": "",
        "cause.labor.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/CL.png",
        "cause.labor.desc1":
            "Companies recognized for fair labor practices. Also recognized are companies scoring highly for work-life balance, such as Colgate-Palmolive and H&R Block.",
        "cause.labor.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_35930.png",
        "cause.labor.title": "Pro-Labor",
        "cause.labor.XXX": "",
        "cause.labor.YYY": "",
        "cause.labor.ZZZ": "",
        "cause.lgbt.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/AT-T.png", // TODO
        "cause.lgbt.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_133748.png",
        "cause.lgbt.title": "LGBTQIA+",
        "cause.lgbt.XXX": "",
        "cause.lgbt.YYY": "",
        "cause.lgbt.ZZZ": "",
        "cause.liver.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.liver.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_196362.png",
        "cause.liver.title": "Liver Diseases",
        "cause.liver.XXX": "",
        "cause.liver.YYY": "",
        "cause.liver.ZZZ": "",
        "cause.marijuana.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_12822.png",
        "cause.marijuana.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_12822.png",
        "cause.marijuana.title": "Marijuana",
        "cause.marijuana.XXX": "",
        "cause.marijuana.YYY": "",
        "cause.marijuana.ZZZ": "",
        "cause.military.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/LMT.png",
        "cause.military.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_213208.png",
        "cause.military.title": "Defense Industry",
        "cause.military.XXX": "",
        "cause.military.YYY": "",
        "cause.military.ZZZ": "",
        "cause.ms.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.ms.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_2219.png",
        "cause.ms.title": "Multiple Sclerosis",
        "cause.ms.XXX": "",
        "cause.ms.YYY": "",
        "cause.ms.ZZZ": "",
        "cause.naturopathy.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.naturopathy.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_10313.png",
        "cause.naturopathy.title": "Naturopathy",
        "cause.naturopathy.XXX": "",
        "cause.naturopathy.YYY": "",
        "cause.naturopathy.ZZZ": "",
        "cause.nonGMO.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/MON.png",
        "cause.nonGMO.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_58377.png",
        "cause.nonGMO.title": "Non-GMO Foods/Cause",
        "cause.nonGMO.XXX": "",
        "cause.nonGMO.YYY": "",
        "cause.nonGMO.ZZZ": "",
        "cause.nuclear.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.nuclear.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_194_Nuclear.png",
        "cause.nuclear.title": "Nuclear Energy",
        "cause.nuclear.XXX": "",
        "cause.nuclear.YYY": "",
        "cause.nuclear.ZZZ": "",
        "cause.oil.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/XOM.png",
        "cause.oil.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_17482.png",
        "cause.oil.title": "Petroleum / Big Oil",
        "cause.oil.XXX": "",
        "cause.oil.YYY": "",
        "cause.oil.ZZZ": "",
        "cause.organicFoods.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/MON.png",
        "cause.organicFoods.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_58378.png",
        "cause.organicFoods.title": "Organic Foods",
        "cause.organicFoods.XXX": "",
        "cause.organicFoods.YYY": "",
        "cause.organicFoods.ZZZ": "",
        "cause.parkinsons.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.parkinsons.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_123938.png",
        "cause.parkinsons.title": "Parkinson's Disease",
        "cause.parkinsons.XXX": "",
        "cause.parkinsons.YYY": "",
        "cause.parkinsons.ZZZ": "",
        "cause.philanthropy.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.philanthropy.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_29964.png",
        "cause.philanthropy.title": "Philanthropy",
        "cause.philanthropy.XXX": "",
        "cause.philanthropy.YYY": "",
        "cause.philanthropy.ZZZ": "",
        "cause.politicalLobbying.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.politicalLobbying.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_171430.png",
        "cause.politicalLobbying.title": "Political Lobbying",
        "cause.politicalLobbying.XXX": "",
        "cause.politicalLobbying.YYY": "",
        "cause.politicalLobbying.ZZZ": "",
        "cause.pornSex.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/LB.png",
        "cause.pornSex.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_127311.png",
        "cause.pornSex.title": "Prurient Interests",
        "cause.pornSex.XXX": "",
        "cause.pornSex.YYY": "",
        "cause.pornSex.ZZZ": "",
        "cause.poverty.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.poverty.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_26617.png",
        "cause.poverty.title": "Stop Poverty",
        "cause.poverty.XXX": "",
        "cause.poverty.YYY": "",
        "cause.poverty.ZZZ": "",
        "cause.proChoice.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.proChoice.desc1":
            "Pro Choice supports the view that a woman should have the legal right to an elective abortion, meaning the right to terminate her pregnancy.",
        "cause.proChoice.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_197005.png",
        "cause.proChoice.title": "Pro-Choice",
        "cause.proChoice.XXX": "",
        "cause.proChoice.YYY": "",
        "cause.proChoice.ZZZ": "",
        "cause.proLife.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.proLife.desc1": "",
        "cause.proLife.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_48330.png",
        "cause.proLife.title": "Pro-Life",
        "cause.proLife.XXX": "",
        "cause.proLife.YYY": "",
        "cause.proLife.ZZZ": "",
        "cause.psychDrugs.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/JNJ.png",
        "cause.psychDrugs.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_12720.png",
        "cause.psychDrugs.title": "Psychiatric Drugs",
        "cause.psychDrugs.XXX": "",
        "cause.psychDrugs.YYY": "",
        "cause.psychDrugs.ZZZ": "",
        "cause.safety.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.safety.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_342665_leafHand.png",
        "cause.safety.title": "Product Safety",
        "cause.safety.XXX": "",
        "cause.safety.YYY": "",
        "cause.safety.ZZZ": "",
        "cause.sciTech.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/JNPR.png",
        "cause.sciTech.desc1":
            "Companies recognized for fair labor practices. Also recognized are companies scoring highly for work-life balance, such as Colgate-Palmolive and H&R Block.",
        "cause.sciTech.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_86666.png",
        "cause.sciTech.title": "Science, Tech., Eng. & Math",
        "cause.sciTech.XXX": "",
        "cause.sciTech.YYY": "",
        "cause.sciTech.ZZZ": "",
        "cause.secondAmendment.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.secondAmendment.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_118458.png",
        "cause.secondAmendment.title": "2nd Amendment (Gun) Rights",
        "cause.secondAmendment.XXX": "",
        "cause.secondAmendment.YYY": "",
        "cause.secondAmendment.ZZZ": "",
        "cause.slavery.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/JNPR.png",
        "cause.slavery.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_89995_unchained.png",
        "cause.slavery.title": "End Slavery/Human Trafficking",
        "cause.slavery.XXX": "",
        "cause.slavery.YYY": "",
        "cause.slavery.ZZZ": "",
        "cause.stemCell.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/CELG.png",
        "cause.stemCell.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_185708.png",
        "cause.stemCell.title": "Stem Cell Use",
        "cause.stemCell.XXX": "",
        "cause.stemCell.YYY": "",
        "cause.stemCell.ZZZ": "",
        "cause.sudan.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.sudan.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_84056.png",
        "cause.sudan.title": "Anti-Sudan",
        "cause.sudan.XXX": "",
        "cause.sudan.YYY": "",
        "cause.sudan.ZZZ": "",
        "cause.sugar.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/KO.png",
        "cause.sugar.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_117830.png",
        "cause.sugar.title": "Big Sugar", //JRE: get new .svg version
        "cause.sugar.XXX": "",
        "cause.sugar.YYY": "",
        "cause.sugar.ZZZ": "",
        "cause.sustainableAgriculture.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.sustainableAgriculture.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_58979.png",
        "cause.sustainableAgriculture.title": "Sustainable Agriculture",
        "cause.sustainableAgriculture.XXX": "",
        "cause.sustainableAgriculture.YYY": "",
        "cause.sustainableAgriculture.ZZZ": "",
        "cause.tobacco.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/PM.png",
        "cause.tobacco.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_142699.png",
        "cause.tobacco.title": "Big Tobacco",
        "cause.tobacco.XXX": "",
        "cause.tobacco.YYY": "",
        "cause.tobacco.ZZZ": "",
        "cause.transEfficiency.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/CMI.png",
        "cause.transEfficiency.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_143378.png",
        "cause.transEfficiency.title": "Transportation Efficiency",
        "cause.transEfficiency.XXX": "",
        "cause.transEfficiency.YYY": "",
        "cause.transEfficiency.ZZZ": "",
        "cause.transparency.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/AMGN.png",
        "cause.transparency.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_24283.png",
        "cause.transparency.title": "Corp. Transparency",
        "cause.transparency.XXX": "",
        "cause.transparency.YYY": "",
        "cause.transparency.ZZZ": "",
        "cause.vaccines.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.vaccines.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_195620.png",
        "cause.vaccines.title": "Vaccines",
        "cause.vaccines.XXX": "",
        "cause.vaccines.YYY": "",
        "cause.vaccines.ZZZ": "",
        "cause.vegan.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/BYND.png",
        "cause.vegan.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_147303.png",
        "cause.vegan.title": "Vegan/Vegetarian",
        "cause.vegan.XXX": "",
        "cause.vegan.YYY": "",
        "cause.vegan.ZZZ": "",
        "cause.veterans.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.veterans.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_199819.png",
        "cause.veterans.title": "Veterans",
        "cause.veterans.XXX": "",
        "cause.veterans.YYY": "",
        "cause.veterans.ZZZ": "",
        "cause.wasteDiversion.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/labsLogo.png",
        "cause.wasteDiversion.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_96758.png",
        "cause.wasteDiversion.title": "Waste Diversion",
        "cause.wasteDiversion.XXX": "",
        "cause.wasteDiversion.YYY": "",
        "cause.wasteDiversion.ZZZ": "",
        "cause.waterConservation.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/MSFT.png",
        "cause.waterConservation.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/noun_142793.png",
        "cause.waterConservation.title": "Water Conservation",
        "cause.waterConservation.XXX": "",
        "cause.waterConservation.YYY": "",
        "cause.waterConservation.ZZZ": "",
        "cause.women.company1logo":
            "https://d3e1j90z5g3six.cloudfront.net/images/logos/INTC.png",
        "cause.women.icon":
            "https://d3e1j90z5g3six.cloudfront.net/images/icons/png/genderEq_400x.png",
        "cause.women.title": "Gender Equality Lens",
        "cause.women.XXX": "",
        "cause.women.YYY": "",
        "cause.women.ZZZ": "",

        // Slider Text from Excel LIVE
    
        "sustainabilityCause.altEnergy.0": "I'm strongly against",
        "sustainabilityCause.altEnergy.25": "against",
        "sustainabilityCause.altEnergy.50": "",
        "sustainabilityCause.altEnergy.75": "in favor/positive",
        "sustainabilityCause.altEnergy.100": "I'm strongly in favor",
        "sustainabilityCause.carbonNeutral.0": "Strong tilt: low carbon",
        "sustainabilityCause.carbonNeutral.25": "Lower carbon tilt",
        "sustainabilityCause.carbonNeutral.50": "",
        "sustainabilityCause.carbonNeutral.75": "High carbon tilt",
        "sustainabilityCause.carbonNeutral.100": "Heavy tilt: high carbon",
        "sustainabilityCause.circularEconomy.0": "I'm strongly against",
        "sustainabilityCause.circularEconomy.25": "against",
        "sustainabilityCause.circularEconomy.50": "",
        "sustainabilityCause.circularEconomy.75": "in favor/positive",
        "sustainabilityCause.circularEconomy.100":
            "I strongly support the Circular Economy",
        "sustainabilityCause.climateChange.0":
            "I believe global warming is a hoax!",
        "sustainabilityCause.climateChange.25": "I swipe left on Green efforts",
        "sustainabilityCause.climateChange.50": "",
        "sustainabilityCause.climateChange.75":
            "I favor sustainability causes",
        "sustainabilityCause.climateChange.100":
            "I strongly support climate urgency causes",
        "sustainabilityCause.coal.0": "I'm strongly against Big Coal",
        "sustainabilityCause.coal.25": "negative",
        "sustainabilityCause.coal.50": "",
        "sustainabilityCause.coal.75": "in favor/positive",
        "sustainabilityCause.coal.100": "I strongly favor of Big Coal",
        "sustainabilityCause.divestment.0":
            "I strongly want to overweight fossil fuels",
        "sustainabilityCause.divestment.25": "negative",
        "sustainabilityCause.divestment.50": "",
        "sustainabilityCause.divestment.75": "I'm in favor of divestment",
        "sustainabilityCause.divestment.100":
            "I'm passionately anti-Fossil Fuel",
        "sustainabilityCause.energyCons.0": "I'm strongly against",
        "sustainabilityCause.energyCons.25": "negative",
        "sustainabilityCause.energyCons.50": "",
        "sustainabilityCause.energyCons.75": "in favor/positive",
        "sustainabilityCause.energyCons.100": "I'm strongly in favor",
        "sustainabilityCause.fossilFuels.0":
            "I'm passionately anti-Fossil Fuel",
        "sustainabilityCause.fossilFuels.25": "against",
        "sustainabilityCause.fossilFuels.50": "",
        "sustainabilityCause.fossilFuels.75": "in favor/positive",
        "sustainabilityCause.fossilFuels.100": "I'm strongly in favor",
        "sustainabilityCause.fracking.0": "I'm strongly against Fracking",
        "sustainabilityCause.fracking.25": "negative",
        "sustainabilityCause.fracking.50": "",
        "sustainabilityCause.fracking.75": "in favor/positive",
        "sustainabilityCause.fracking.100": "I'm strongly in favor",
        "sustainabilityCause.nuclear.0": "I'm strongly against",
        "sustainabilityCause.nuclear.25": "negative",
        "sustainabilityCause.nuclear.50": "",
        "sustainabilityCause.nuclear.75": "in favor/positive",
        "sustainabilityCause.nuclear.100": "I'm strongly in favor",
        "sustainabilityCause.oil.0": "I'm strongly against Big Oil",
        "sustainabilityCause.oil.25": "negative",
        "sustainabilityCause.oil.50": "",
        "sustainabilityCause.oil.75": "in favor/positive",
        "sustainabilityCause.oil.100": "I'm strongly in favor of Big Oil",
        "sustainabilityCause.sustainableAgriculture.0": "I'm strongly against",
        "sustainabilityCause.sustainableAgriculture.25": "against",
        "sustainabilityCause.sustainableAgriculture.50": "",
        "sustainabilityCause.sustainableAgriculture.75": "in favor/positive",
        "sustainabilityCause.sustainableAgriculture.100":
            "I'm strongly in favor",
        "sustainabilityCause.transEfficiency.0": "I'm strongly against",
        "sustainabilityCause.transEfficiency.25": "against",
        "sustainabilityCause.transEfficiency.50": "",
        "sustainabilityCause.transEfficiency.75": "in favor/positive",
        "sustainabilityCause.transEfficiency.100": "I'm strongly in favor",
        "sustainabilityCause.wasteDiversion.0": "I'm strongly against",
        "sustainabilityCause.wasteDiversion.25": "against",
        "sustainabilityCause.wasteDiversion.50": "",
        "sustainabilityCause.wasteDiversion.75": "in favor/positive",
        "sustainabilityCause.wasteDiversion.100": "I'm strongly in favor",
        "sustainabilityCause.waterConservation.0": "I'm strongly against",
        "sustainabilityCause.waterConservation.25": "against",
        "sustainabilityCause.waterConservation.50": "",
        "sustainabilityCause.waterConservation.75": "in favor/positive",
        "sustainabilityCause.waterConservation.100": "I'm strongly in favor",
        "socialCause.children.0": "Seriously?!?! [neutral]",
        "socialCause.children.25": "neutral",
        "socialCause.children.50": "",
        "socialCause.children.75": "in favor/positive",
        "socialCause.children.100": "I'm strongly want to support children",
        "socialCause.causeDisabled.0": "",
        "socialCause.causeDisabled.25": "",
        "socialCause.causeDisabled.50": "",
        "socialCause.causeDisabled.75": "in favor/positive",
        "socialCause.causeDisabled.100": "I'm strongly in favor",
        "socialCause.diversity.0": "",
        "socialCause.diversity.25": "",
        "socialCause.diversity.50": "",
        "socialCause.diversity.75": "in favor/positive",
        "socialCause.diversity.100": "I'm strongly in favor",
        "artCause.education.0": "I feel strongly against supporting education",
        "artCause.education.25": "",
        "artCause.education.50": "",
        "artCause.education.75": "in favor/positive",
        "artCause.education.100": "I'm strongly in favor",
        "socialCause.girls.0": "",
        "socialCause.girls.25": "",
        "socialCause.girls.50": "",
        "socialCause.girls.75": "in favor/positive",
        "socialCause.girls.100": "I'm strongly pro-Girls",
        "socialCause.humanRights.0": "",
        "socialCause.humanRights.25": "",
        "socialCause.humanRights.50": "",
        "socialCause.humanRights.75": "in favor/positive",
        "socialCause.humanRights.100": "I'm strongly in favor",
        "socialCause.immigration.0": "",
        "socialCause.immigration.25": "",
        "socialCause.immigration.50": "",
        "socialCause.immigration.75": "in favor/positive",
        "socialCause.immigration.100":
            "I'm strongly in favor of Immigration rights",
        "socialCause.labor.0": "I'm strongly against",
        "socialCause.labor.25": "neutral",
        "socialCause.labor.50": "",
        "socialCause.labor.75": "in favor/positive",
        "socialCause.labor.100": "I'm strongly in favor",
        "socialCause.lgbt.0": "",
        "socialCause.lgbt.25": "",
        "socialCause.lgbt.50": "",
        "socialCause.lgbt.75": "in favor/positive",
        "socialCause.lgbt.100": "I'm strongly in favor",
        "socialCause.marriageRights.0": "neutral",
        "socialCause.marriageRights.25": "neutral",
        "socialCause.marriageRights.50": "",
        "socialCause.marriageRights.75": "in favor/positive",
        "socialCause.marriageRights.100": "strongly in favor",
        "socialCause.philanthropy.0": "I'm strongly against",
        "socialCause.philanthropy.25": "against",
        "socialCause.philanthropy.50": "",
        "socialCause.philanthropy.75": "in favor/positive",
        "socialCause.philanthropy.100": "I'm strongly in favor",
        "socialCause.poverty.0": "I'm strongly against",
        "socialCause.poverty.25": "against",
        "socialCause.poverty.50": "",
        "socialCause.poverty.75": "in favor/positive",
        "socialCause.poverty.100": "I'm strongly in favor",
        "socialCause.proChoice.0": "I'm strongly Pro-Life",
        "socialCause.proChoice.25": "I favor Pro-Life",
        "socialCause.proChoice.50": "",
        "socialCause.proChoice.75": "I favor Pro-Choice",
        "socialCause.proChoice.100": "I'm strongly Pro-Choice",
        "socialCause.veterans.0": "I'm strongly against",
        "socialCause.veterans.25": "against",
        "socialCause.veterans.50": "",
        "socialCause.veterans.75": "in favor/positive",
        "socialCause.veterans.100": "I'm strongly in favor",
        "socialCause.women.0": "",
        "socialCause.women.25": "",
        "socialCause.women.50": "",
        "socialCause.women.75": "in favor/positive",
        "socialCause.women.100": "I'm strongly in favor",
        "peaceCause.animals.0": "I am anti-animal rights",
        "peaceCause.animals.25": "against",
        "peaceCause.animals.50": "",
        "peaceCause.animals.75": "in favor/positive",
        "peaceCause.animals.100": "I'm strongly in favor",
        "peaceCause.fightISIS.0": "I am against this war on ISIS",
        "peaceCause.fightISIS.25": "against",
        "peaceCause.fightISIS.50": "",
        "peaceCause.fightISIS.75": "in favor/positive",
        "peaceCause.fightISIS.100": "I'm strongly in favor",
        "peaceCause.fightTerrorism.0": "I am against the War on Terrorism",
        "peaceCause.fightTerrorism.25": "negative",
        "peaceCause.fightTerrorism.50": "",
        "peaceCause.fightTerrorism.75": "in favor/positive",
        "peaceCause.fightTerrorism.100": "I'm strongly in favor",
        "peaceCause.gunControl.0": "I strongly support 2nd Amendment Rights",
        "peaceCause.gunControl.25": "I support Gun Rights",
        "peaceCause.gunControl.50": "",
        "peaceCause.gunControl.75": "in favor/positive",
        "peaceCause.gunControl.100": "I'm strongly in favor of Gun Control",
        "peaceCause.military.0": "Strongly against profiting from military ops.",
        "peaceCause.military.25": "I'm against profiting from land mines, etc.",
        "peaceCause.military.50": "",
        "peaceCause.military.75": "I'm in favor of the defense industry",
        "peaceCause.military.100": "I'm strongly in favor of defense/aerospace industry",
        "peaceCause.secondAmendment.0": "I strongly support Gun Control",
        "peaceCause.secondAmendment.25": "I prefer Gun Control",
        "peaceCause.secondAmendment.50": "",
        "peaceCause.secondAmendment.75": "I support the 2nd Amendment",
        "peaceCause.secondAmendment.100":
            "I strongly support the Right to Bear Arms",
        "peaceCause.sudan.0": "neutral",
        "peaceCause.sudan.25": "neutral",
        "peaceCause.sudan.50": "",
        "peaceCause.sudan.75": "in favor",
        "peaceCause.sudan.100": "I'm strongly anti-Sudan gov't",
        "peaceCause.slavery.0": "neutral",
        "peaceCause.slavery.25": "neutral",
        "peaceCause.slavery.50": "",
        "peaceCause.slavery.75": "in favor",
        "peaceCause.slavery.100": "Let's end slavery and human-trafficking",
        "healthCause.aids.0": "I'm just evil",
        "healthCause.aids.25": "against",
        "healthCause.aids.50": "",
        "healthCause.aids.75": "in favor/positive",
        "healthCause.aids.100": "I feel strongly about the fight to cure AIDS",
        "healthCause.alzheimers.0": "I'm just evil",
        "healthCause.alzheimers.25": "against",
        "healthCause.alzheimers.50": "",
        "healthCause.alzheimers.75": "in favor/positive",
        "healthCause.alzheimers.100":
            "I feel strongly about the fight to cure Alzheimer's",
        "healthCause.cancerBreast.0": "I'm strongly against",
        "healthCause.cancerBreast.25": "against",
        "healthCause.cancerBreast.50": "",
        "healthCause.cancerBreast.75": "in favor/positive",
        "healthCause.cancerBreast.100":
            "I'm strongly in favor of Breast Health",
        "healthCause.cancer.0": "I'm strongly against",
        "healthCause.cancer.25": "against",
        "healthCause.cancer.50": "",
        "healthCause.cancer.75": "in favor/positive",
        "healthCause.cancer.100": "I strongly support",
        "healthCause.diabetes.0": "I'm strongly against",
        "healthCause.diabetes.25": "against",
        "healthCause.diabetes.50": "",
        "healthCause.diabetes.75": "in favor/positive",
        "healthCause.diabetes.100": "I strongly support solutions for Diabetes",
        "healthCause.health.0":
            "I prefer to invest in couches and potato chips",
        "healthCause.health.25": "against",
        "healthCause.health.50": "",
        "healthCause.health.75": "in favor/positive",
        "healthCause.health.100": "I'm strongly in favor",
        "healthCause.heartDisease.0": "I'm strongly against",
        "healthCause.heartDisease.25": "against",
        "healthCause.heartDisease.50": "",
        "healthCause.heartDisease.75": "in favor/positive",
        "healthCause.heartDisease.100": "I'm strongly in favor",
        "healthCause.liver.0": "I'm just evil",
        "healthCause.liver.25": "against",
        "healthCause.liver.50": "",
        "healthCause.liver.75": "in favor/positive",
        "healthCause.liver.100": "I'm strongly in favor",
        "healthCause.ms.0": "I'm just evil",
        "healthCause.ms.25": "against",
        "healthCause.ms.50": "",
        "healthCause.ms.75": "in favor/positive",
        "healthCause.ms.100": "I'm strongly in favor",
        "healthCause.naturopathy.0": "I'm strongly against",
        "healthCause.naturopathy.25": "against",
        "healthCause.naturopathy.50": "",
        "healthCause.naturopathy.75": "in favor/positive",
        "healthCause.naturopathy.100": "I'm strongly in favor",
        "healthCause.parkinsons.0": "I'm just evil",
        "healthCause.parkinsons.25": "against",
        "healthCause.parkinsons.50": "",
        "healthCause.parkinsons.75": "in favor/positive",
        "healthCause.parkinsons.100": "I'm strongly in favor",
        "healthCause.safety.0":
            "I prefer to invest in couches and potato chips",
        "healthCause.safety.25": "against",
        "healthCause.safety.50": "",
        "healthCause.safety.75": "in favor/positive",
        "healthCause.safety.100": "I'm strongly in favor",
        "healthCause.stemCell.0": "I'm strongly against",
        "healthCause.stemCell.25": "against",
        "healthCause.stemCell.50": "",
        "healthCause.stemCell.75": "in favor/positive",
        "healthCause.stemCell.100": "I'm strongly in favor",
        "healthCause.vaccines.0": "strongly against the vaccine industry",
        "healthCause.vaccines.25": "against",
        "healthCause.vaccines.50": "",
        "healthCause.vaccines.75": "in favor/positive",
        "healthCause.vaccines.100": "I'm strongly in favor of vaccines",
        "foodCause.dairy.0": "I'm strongly against Dairy products",
        "foodCause.dairy.25": "I'm opposed to Dairy",
        "foodCause.dairy.50": "",
        "foodCause.dairy.75": "in favor/positive",
        "foodCause.dairy.100": "I'm strongly in favor of Big Dairy",
        "foodCause.fastFood.0": "I'm strongly against the Fast Food industry",
        "foodCause.fastFood.25": "I'm opposed to Fast Food",
        "foodCause.fastFood.50": "",
        "foodCause.fastFood.75": "in favor/positive",
        "foodCause.fastFood.100": "I'm strongly in favor of Fast Food",
        "foodCause.gluten.0": "I'm strongly against gluten/GMO-wheat products",
        "foodCause.gluten.25": "anti-gluten, anti-GMO-wheat",
        "foodCause.gluten.50": "",
        "foodCause.gluten.75": "I like gluten/wheat",
        "foodCause.gluten.100": "I am strongly in favor of modern wheat/gluten",
        "foodCause.humaneAnimalFarming.0": "I don't care about Wilbur.",
        "foodCause.humaneAnimalFarming.25": "It's not important to me",
        "foodCause.humaneAnimalFarming.50": "",
        "foodCause.humaneAnimalFarming.75":
            "I favor humane treatment of animals",
        "foodCause.humaneAnimalFarming.100": "I'm strongly in favor",
        "foodCause.nonGMO.0": "I love science; I'm strongly Pro-GMO!",
        "foodCause.nonGMO.25": "I'm in favor of GMO companies & products",
        "foodCause.nonGMO.50": "",
        "foodCause.nonGMO.75": "I prefer/support Non-GMO efforts",
        "foodCause.nonGMO.100": "I am strongly anti-GMO!",
        "foodCause.organicFoods.0":
            "I'm strongly against Organic Food companies",
        "foodCause.organicFoods.25":
            "I tend to prefer non-Organic food companies",
        "foodCause.organicFoods.50": "",
        "foodCause.organicFoods.75": "in favor/positive",
        "foodCause.organicFoods.100": "I'm strongly in favor of Organic Foods",
        "foodCause.sugar.0": "I'm anti-Sugar",
        "foodCause.sugar.25": "opposed",
        "foodCause.sugar.50": "",
        "foodCause.sugar.75": "in favor/positive",
        "foodCause.sugar.100": "I'm strongly in favor of Sugar",
        "foodCause.vegan.0": "Bring on all that meat and CO₂!",
        "foodCause.vegan.25": "Give me a pro-meat tilt",
        "foodCause.vegan.50": "",
        "foodCause.vegan.75": "Flexatarian: I'd like a pro-vegan tilt",
        "foodCause.vegan.100": "Consider me full-vegan; cruelty free",
        "drugsCause.alcohol.0": "I'm strongly against Alcohol",
        "drugsCause.alcohol.25": "I'm opposed to Alcohol",
        "drugsCause.alcohol.50": "",
        "drugsCause.alcohol.75": "in favor/positive",
        "drugsCause.alcohol.100": "I'm strongly in favor of Big Alcohol",
        "drugsCause.drugs.0": "I'm strongly against Drugs in General",
        "drugsCause.drugs.25": "I'm opposed to Drugs in General",
        "drugsCause.drugs.50": "",
        "drugsCause.drugs.75": "in favor/positive",
        "drugsCause.drugs.100": "I'm strongly in favor of Drugs",
        "drugsCause.gambling.0": "I'm strongly against Gambling",
        "drugsCause.gambling.25": "I'm opposed to Gambling",
        "drugsCause.gambling.50": "",
        "drugsCause.gambling.75": "in favor/positive",
        "drugsCause.gambling.100": "I'm strongly in favor",
        "drugsCause.marijuana.0": "I'm strongly against Marijuana",
        "drugsCause.marijuana.25": "I'm opposed to Marijuana",
        "drugsCause.marijuana.50": "",
        "drugsCause.marijuana.75": "in favor/positive",
        "drugsCause.marijuana.100": "I'm strongly in favor",
        "religionCause.pornSex.0": "I'm strongly against Prurient Interests",
        "religionCause.pornSex.25": "I'm opposed to Prurient Interests",
        "religionCause.pornSex.50": "",
        "religionCause.pornSex.75": "in favor/positive",
        "religionCause.pornSex.100": "I'm strongly in favor",
        "drugsCause.psychDrugs.0": "I'm strongly against Psychiatric Drugging",
        "drugsCause.psychDrugs.25": "I'm opposed to Psychiatric Drugs",
        "drugsCause.psychDrugs.50": "",
        "drugsCause.psychDrugs.75": "in favor/positive",
        "drugsCause.psychDrugs.100": "I'm strongly in favor of Big Pharma",
        "drugsCause.tobacco.0": "I'm against profiting from addiction",
        "drugsCause.tobacco.25": "I'm opposed to Tobacco",
        "drugsCause.tobacco.50": "",
        "drugsCause.tobacco.75": "in favor/positive",
        "drugsCause.tobacco.100": "I'm a big fan of Big Tobacco",
        "corporateCause.bCorp.0":
            "I'm strongly against the B Corporation cause",
        "corporateCause.bCorp.25": "I don't like 'em",
        "corporateCause.bCorp.50": "",
        "corporateCause.bCorp.75": "in favor/positive",
        "corporateCause.bCorp.100": "I'm strongly in favor",
        "corporateCause.governance.0": "strongly against",
        "corporateCause.governance.25": "against",
        "corporateCause.governance.50": "",
        "corporateCause.governance.75": "in favor/positive",
        "corporateCause.governance.100": "I'm strongly in favor",
        "corporateCause.politicalLobbying.0":
            "I'm strongly against political interference!",
        "corporateCause.politicalLobbying.25":
            "I'm opposed to Political Lobbying",
        "corporateCause.politicalLobbying.50": "",
        "corporateCause.politicalLobbying.75": "in favor/positive",
        "corporateCause.politicalLobbying.100": "The more lobbying the better!",
        "corporateCause.transparency.0":
            "I strongly prefer companies neglecting any duty of disclosure",
        "corporateCause.transparency.25": "I'm opposed to corporate disclosure",
        "corporateCause.transparency.50": "",
        "corporateCause.transparency.75": "Disclosure is important to me",
        "corporateCause.transparency.100":
            "I'm strongly prefer companies with proper disclosure",
        "artCause.arts.0": "I'm strongly against the Arts",
        "artCause.arts.25": "negative",
        "artCause.arts.50": "",
        "artCause.arts.75": "in favor/positive",
        "artCause.arts.100": "I'm strongly in favor",
        "artCause.culture.0": "I'm strongly against Culture",
        "artCause.culture.25": "negative",
        "artCause.culture.50": "",
        "artCause.culture.75": "in favor/positive",
        "artCause.culture.100": "I'm strongly in favor",
        "artCause.fashionStyle.0": "I'm strongly against",
        "artCause.fashionStyle.25": "negative",
        "artCause.fashionStyle.50": "",
        "artCause.fashionStyle.75": "in favor/positive",
        "artCause.fashionStyle.100": "I'm strongly in favor",
        "artCause.sciTech.0": "I'm against supporters of STEM",
        "artCause.sciTech.25": "negative",
        "artCause.sciTech.50": "",
        "artCause.sciTech.75": "I'm pro-Sci, Tech, Eng & Math education",
        "artCause.sciTech.100": "I strongly support Sci, Tech, Eng & Math",
        "religionCause.proLife.0": "I'm strongly Pro-Choice",
        "religionCause.proLife.25": "I favor Pro-Choice",
        "religionCause.proLife.50": "",
        "religionCause.proLife.75": "I favor Pro-Life",
        "religionCause.proLife.100": "I'm strongly Pro-Life",
        "religionCause.recDrugs.0": "Strongly against",
        "religionCause.recDrugs.25": "against",
        "religionCause.recDrugs.50": "",
        "religionCause.recDrugs.75": "in favor",
        "religionCause.recDrugs.100": "I'm strongly in favor",

        "finishSelections.socialSelectivity.0":
            "I'm only mildly Socially-Selective",
        "finishSelections.socialSelectivity.25": "below average",
        "finishSelections.socialSelectivity.50": "average",
        "finishSelections.socialSelectivity.75": "above average",
        "finishSelections.socialSelectivity.100":
            "Consider me highly selective",
        "finishSelections.taxSensitivity.0": "I don't pay taxes at all",
        "finishSelections.taxSensitivity.25":
            "I don't mind paying taxes on my investments",
        "finishSelections.taxSensitivity.50": "average",
        "finishSelections.taxSensitivity.75":
            "I am focused on after-tax return.",
        "finishSelections.taxSensitivity.100":
            "I have a high tax rate -- minimize my tax bill!",
        "finishSelections.riskTolerance.0":
            "I'm very averse -- very low tolerance for volatility",
        "finishSelections.riskTolerance.25":
            "I'm more risk averse than average",
        "finishSelections.riskTolerance.50": "average",
        "finishSelections.riskTolerance.75":
            "higher than average risk tolerance",
        "finishSelections.riskTolerance.100":
            "I can tolerate a higher risk/reward profile",
        "finishSelections.investmentHorizon.0": "1-2 years",
        "finishSelections.investmentHorizon.25": "3-5 years",
        "finishSelections.investmentHorizon.50": "5-7 years",
        "finishSelections.investmentHorizon.75": "7-15 years",
        "finishSelections.investmentHorizon.100":
            "15+ years: long-term investing",
        "finishSelections.accountSize.0": "less than $100,000",
        "finishSelections.accountSize.25": "$100K-$250K",
        "finishSelections.accountSize.50": "$250K - $1 million",
        "finishSelections.accountSize.75": "$1-3 million",
        "finishSelections.accountSize.100": "$3,000,000+",
        "finishSelections.age.0": "up to 25 years old",
        "finishSelections.age.25": "26-35",
        "finishSelections.age.50": "36-45",
        "finishSelections.age.75": "46-59",
        "finishSelections.age.100": "60+",

        // cards - here you add the info from meteor
        // pattern cards.{causeId}
        "cards.climateChange": "Global climate change is happening: glaciers have shrunk, animal ranges have shifted and trees are flowering sooner.<br><br>Effects that scientists had predicted in the past would result from global climate change are now occurring: loss of sea ice, accelerated sea level rise and longer, more intense heat waves.<br><br>Scientists have high confidence that global temperatures will continue to rise for decades to come, <b>largely due to greenhouse gases produced by human activities</b>. The Intergovernmental Panel on Climate Change (IPCC), which includes more than 1,300 scientists from the United States and other countries, forecasts a temperature rise of 2.5° to 10°F over the next century, vs. <a href='https://climate.nasa.gov/effects/'>+2°F last century</a>.<br><br>Here is our favorite high-level view of energy consumption in the U.S. And you can further drill down to your state if you tap below<br><br><a href='https://flowcharts.llnl.gov/commodities/energy' ><img src='https://flowcharts.llnl.gov/content/assets/images/charts/Energy/Energy_2018_United-States.png' style='width:100%; height:100%;'></a>",
        "cards.governance": "The system of rules, practices and processes by which a company is directed and controlled: balancing the interests of the many stakeholders in a company: shareholders, management, customers, suppliers, financiers, government and the community.<br><br>The board of directors is primarily responsible for applying and maintaining a company's corporate governance.<br><br>Are there checks and balances? How independent is the board?",
        "ticker.governance": "Are there checks and balances? How independent is the board which is ultimately responsible for corporate governance, protecting shareholder interests. 🛡",

        "cards.sugar": "Since 1990 obesity has increased from <a href='https://obesity.procon.org/us-obesity-levels-by-state/'>11% to over 30%</a>.<br><br>QB <b>Tom Brady</b> <a href='http://www.wsj.com/articles/tom-brady-calls-coke-poison-for-kids-1444689948'>called out</a> <b>Coca-Cola</b> as 'poison for kids'. If you agree, you will own ZERO shares of <b>Pepsi, Coca-Cola, Kellogg's</b>, etc.<br><br>In 1990, no state in the U.S. reported obesity above Mississippi's 15%:<br><br><center><a href='http://obesity.procon.org/view.resource.php?resourceID=006026'><img src='https://images.procon.org/wp-content/uploads/sites/15/1990-us-obesity-map.jpg' style=' width:100%; height:100%;'></a></center><br><br>Today, every state exceeds Colorado's 22%:<br><br><center><a href='http://obesity.procon.org/view.resource.php?resourceID=006026'><img src='https://images.procon.org/wp-content/uploads/sites/15/2017-us-obesity-map.jpg' style=' width:100%; height:100%;'></a></center><br><b<br>Tap below for more data and our favorite GIF from ProCon.org:<br><br><center><a href='http://obesity.procon.org/view.resource.php?resourceID=006026'><img src='https://images.procon.org/wp-content/uploads/sites/15/us-obesity-levels-1990-2017.gif' style=' width:100%; height:100%;'></a></center>",
        "cards.women": "<div><b>Women Elevation in Corporate America</b> or gender equality, <a href='https://www.un.org/sustainabledevelopment/gender-equality/'><b>U.N. Sustainable Development Goal (SDG) 5</b></a>.<br><br>Today, women hold <a href='https://www.catalyst.org/research/women-ceos-of-the-sp-500/'><b>only ~6% of the CEO-positions</b></a> of the S&P 500 companies. At the same time, women control <a href='https://bmogamviewpoints.com/women/'><b>51% of personal wealth</b></a> in the U.S.<br><br>Women are the primary breadwinners in over <b>40%</b> of households, a four-fold increase since 1960. Women now hold <b>52%</b> of management, professional and related positions in the U.S. and own <b>30%</b> of all private businesses.<br><br>Meanwhile women earn <b>78 cents</b> for every dollar a man earns. 2/3 of primary caregivers are women. And rising health care costs have a disproportionate impact on women, given that women tend to live longer.<br><br>One example of a company making an impact is <b>Salesforce</b>. CEO Mark Benioff: <em><q>We can say we pay women the same that we pay men.</q></em>  They spent an extra $3M in 2015 to close the gap after a comprehensive review of its 17,000 employees. <em><q>We looked at every single salary.</q></em><br><br><b>Intel</b> closed their gender and racial pay gap in 2017. In <a href='https://newsroom.intel.com/editorials/intel-achieves-gender-pay-equity-globally/'>2019 they took it a step further</a> when they announced: <em><q>a major milestone in global inclusion — achieving gender pay equity across our worldwide workforce while also <b>adding stock-based compensation to our pay equity analysis</b>.</q></em></div>",
        "cards.tobacco": "Smoking prevalence in the U.S. has declined from 42%+ in the 1960s to <a href='https://www.cdc.gov/tobacco/data_statistics/fact_sheets/fast_facts/index.htm#us-smoking'>14%</a> today.<br><br>Smoking-related illnesses cost <b>$300B/yr</b> (offset by the <b>$10B</b> added to the economy by Tobacco advertising). Ironically, two of the largest investors in <img src='https://d3e1j90z5g3six.cloudfront.net/images/logos/PM.png' class='company-logo'> <b>Philip Morris</b> are <em>529 college savings plans</em>.<br><br><a href='http://www.cdc.gov/tobacco/data_statistics/fact_sheets/fast_facts/index.htm'>cdc.gov</a>; &nbsp; <a href='https://www.tobaccofreekids.org/assets/global/pdfs/en/Global_Cigarette_Industry_pdf.pdf'>tobaccofreekids.org</a><br><br>At Impact Labs, we believe in freedom of choice.<br><br>As investment professionals, we believe that if you choose to increase your investments in addiction, this should not be considered an <em>investment edge</em>.<br><br>With trading stocks, as with any other second-hand good, it's hard to argue you have an <em>edge</em> when you have the same information as the other side of the transaction choosing to sell you their shares that day.",
        "ticker.psychDrugs": "<a href='http://www.newyorker.com/tech/elements/the-psychiatric-drug-crisis'>~20% of Americans</a> now regularly take mind-altering drugs prescribed by their doctors. Meanwhile, psychiatric drugs cause ½M adverse effects/year, resulting in 90,000 ER visits, 19,000 birth defects, 25,000 suicides. There are 600M psychiatirc drug Rxs/year; 20M kids are drugged, including 770,000 aged 0-5.",
        "cards.psychDrugs": "<a href='http://www.newyorker.com/tech/elements/the-psychiatric-drug-crisis'>~20% of Americans</a> now regularly take mind-altering drugs prescribed by their doctors. Psychiatric drugs have been extremely profitable affording billions of dollars for marketing campaigns each year.<br><br>Meanwhile, psychiatric drugs cause ½M adverse effects/year, resulting in 90,000 ER visits, 19,000 birth defects, 25,000 suicides. There are 600M psychiatirc drug Rxs/year; 20M kids are drugged, including 770,000 aged 0-5.<br><br>Actor Tim Robbins has been outspoken in the past: <q>They wind up medicating these kids, and then the kids find their way into self-medication along with that medication, and that's a recipe for disaster.</q><br><br><a href='https://www.forbes.com/sites/erikakelton/2012/04/17/new-jj-ceos-ties-to-fraud-case-show-jj-sees-no-need-for-a-cure'>According to the Dept. of Justice</a>: <img src='https://d3e1j90z5g3six.cloudfront.net/images/logos/JNJ.png' class='company-logo'>&nbsp;<b>Johnson & Johnson</b>'s Marketing VP, Alex Gorsky, <q>was actively involved</q> in fraudulent Risperdal mktg to elderly patients as safe, when in fact it's associated with early death, leading to a $2.2B settlement w/ U.S. Gov't. And today, Gorsky is <b>J&J</b>'s CEO.",
        "cards.military": "The U.S. spends more on defense than the next seven countries combined. You have the choice to profit from this, or not. <br><a href='https://www.pgpf.org/chart-archive/0053_defense-comparison' ><img src='https://www.pgpf.org/sites/default/files/0053_defense_comparison-full.gif' style='width:100%; height:100%;'></a><br><br>At Impact Labs, we believe in freedom of choice.<br><br>As investment professionals, we believe that if you choose to increase your investments in land mines and fighter-jets, this should not be considered an <em>investment edge</em>.<br><br>With trading stocks, as with any other second-hand good, it's hard to argue you have an <em>edge</em> when you have the same information as the other side of the transaction choosing to sell you their shares that day.",
        "ticker.military": "The U.S. <a href='https://www.pgpf.org/chart-archive/0053_defense-comparison' >spends more on defense</a> than the next seven countries combined. Profiting from this is your choice. ",
        "cards.vegan": "Livestock farming produces <a href='http://www.fao.org/news/story/en/item/197623/icode/'>15%</a> of all human-caused greenhouse gas emissions.<br><br><a href='http://www.greeneatz.com/foods-carbon-footprint.html' >A vegetarian's carbon foodprint is half that of a meat-lover's.</a><br><br>You can reduce your foodprint by a quarter just by cutting down on red meats such as beef and lamb.<br><br><a href='http://www.greeneatz.com/foods-carbon-footprint.html' ><img src='http://www.greeneatz.com/wp-content/uploads/2013/01/foods-carbon-footprint-7.gif' style='object-fit: contain; max-width: 100%; max-height: 100%; width: auto; height: auto;'></a>",
        "ticker.vegan": "Livestock farming produces <a href='https://www.fao.org/news/story/en/item/197623/icode/'>15%</a> of all human-caused greenhouse gas emissions. <a href='http://www.greeneatz.com/foods-carbon-footprint.html' >A vegetarian's carbon foodprint is half that of a meat-lover's.</a> You can reduce your foodprint by a quarter just by cutting down on red meats such as beef and lamb.",
        "cards.humanRights": "Human rights is perhaps the biggest issue on the horizon between the two largest economies in the world.<br><br>Search the 17" + dataConfig.emojiSDG2 + "SDGs and “human rights” is <a href='https://qz.com/africa/1299149/how-the-uns-sustainable-development-goals-undermine-democracy/' >mentioned merely once and you will fail to find</a> a single mention of the word “democracy.”<br><br>With this cause you can make up for any political bias or limitation at the U.N. by increasing your human rights tilt.",
        "ticker.humanRights": "Search the 17" + dataConfig.emojiSDG2 + "SDGs and “human rights” is <a href='https://qz.com/africa/1299149/how-the-uns-sustainable-development-goals-undermine-democracy/' >mentioned merely once and you will fail to find</a> a single mention of the word “democracy.” With this cause you can increase your human rights tilt.",
        "cards.slavery": "Companies like <img src='https://d3e1j90z5g3six.cloudfront.net/images/logos/JNPR.png' class='company-logo'> <b>Juniper Networks</b> are making a positive impact in the fight to end slavery.<br><br> Since its inception over a decade ago, the <a href='http://www.juniper.net/us/en/company/citizenship-sustainability/community/'>Juniper Networks Foundation Fund</a> has granted more than $11M. The fund focuses on two groups globally: women & children victimized by human trafficking & slavery, and K­12 STEM education programs for communities in need. The Foundation is in partnership with <a href='https://www.notforsalecampaign.org/''>Not For Sale</a>, a non-profit dedicated to ending modern day slavery and human trafficking. The Foundation has also backed HOME Soup and REBBL Tonic to bring new opportunities to victims and their communities.",
        "ticker.slavery": "Companies like <b>Juniper Networks</b> are making a positive impact in the fight to end human trafficking and slavery",

        "cards.health": "This cause is about promoting healthy activities such as exercise, a healty diet and a work-life balance.<br><br>" + dataConfig.emojiSDGdot + "3: Ensure healthy lives and promote wellbeing for all at all ages.",
        "ticker.health": "This cause is about promoting healthy activities such as exercise, a healty diet and a work-life balance.<br><br>" + dataConfig.emojiSDG2 + "Sustainable Development Goal 3: Ensure healthy lives and promote well-being for all at all ages.",

        "cards.lgbt": "This cause will increase ownership of companies promoting civil rights & general equality for the LGBTQ+ community.<br><br>For example:<br><br><img src='https://d3e1j90z5g3six.cloudfront.net/images/logos/AT-T.png' class='company-logo'> <b>AT&T</b> was the first major U.S. co. to publicly condemn Russia's anti-LGBT laws.<br><br><img src='https://d3e1j90z5g3six.cloudfront.net/images/logos/AAPL.png' class='company-logo'> <b>Apple</b> has a long history of celebrating diversity & equality: <b>CEO, Tim Cook:</b>, <em><q>So let me be clear: I'm proud to be gay, and I consider being gay among the greatest gifts God has given me.</q></em>", // https://d3e1j90z5g3six.cloudfront.net/images/logos/AAPL.png
        "cards.transparency": "Corporate transparency describes the extent to which a corporation's actions are observable by outsiders and this is largely driving by corporate disclosure. The word <q>transparent</q> can be used to describe high-quality financial statements.<br><br><a href='http://hipinvestor.com/how-clients-use-hip/ratings/'>HIP Investor</a> provides ratings on thousands of companies. Transparency is one of dozens of dimensions.<br><br>Some top-scoring companies at HIP were: Amgen, Autodesk, BNY Mellon, Colgate-Palmolive, ConocoPhillips, Dow Chemical, JP Morgan, Merck and Occidental Petroleum.",


        "cards.altEnergy": "Here is our favorite high-level view of energy consumption in the U.S. And you can further drill down to your state if you tap below<br><br><a href='https://flowcharts.llnl.gov/commodities/energy' ><img src='https://flowcharts.llnl.gov/content/assets/images/charts/Energy/Energy_2018_United-States.png' style='width:100%; height:100%;'></a>",

        "confirmModal.modalShowPerf.impactText" : "<div class='tooltip'><small><span class='tooltiptext'>What about profit?</span></small>" + dataConfig.impactFooter + "<br/>" + dataConfig.impactEmoji,
        "confirmModal.modalShowPerf.imageTap" : "<div class='tooltip'><span class='tooltiptext'>more</span><div class='confirmModal-perf noBorder'><br><b>What if I just care about profit?</b><br><img src='https://d3e1j90z5g3six.cloudfront.net/images/other/USSIF_2018a.png' style='height:100px; ' /></div></div>",
        "confirmModal.modalShowPerf.title" : "But what about profit?",
        "confirmModal.modalShowPerf.icon" : "https://d3e1j90z5g3six.cloudfront.net/images/other/GreenProfit_320x.png",
        "confirmModal.modalShowPerf.html" : "<img src='https://d3e1j90z5g3six.cloudfront.net/images/other/USSIF_2018_crop.png' style='width:100%; height:100%;'><br><br>Even with the monumental growth in social/impact investing, most professional investors are still single-mindedly focused on beating the average each year. But more often than not, they fail to outperform.<br><br>If investing in the bad actors out there -- companies promoting or contributing to global warming, addiction, inequality, etc. -- offered even a sliver of an <em>investment edge -- or profit advantage --</em> wouldn't the active investors have an easier time at their job?<br><br>So if there is no material edge from investing against your core values, why do it?<br><br>What side do you want to be on?",

        "confirmModal.modalRespInvest.imageTap" : "<div class='tooltip'><span class='tooltiptext'>more</span><div class='confirmModal-perf confirmModal-respInv' >Three Pillars of Responsible Investing<span style='vertical-align: super; font-size: 0.5em; line-height: 1em;'>℠</span><br><br><b>Returns</b>: maximized<br><b>Risk</b>: optimized<br><b>Impact</b>: quantified<br><small><em>CO₂ footprint & custom ESG metrics</em><br><br></small></div></div>",
        "confirmModal.modalRespInvest.title" : "Three Pillars of Responsible Investing℠",
        "confirmModal.modalRespInvest.icon" : "https://d3e1j90z5g3six.cloudfront.net/images/other/GreenProfit_320x.png",
        "confirmModal.modalRespInvest.html" : "<div class='confirmModalBody' >① ② ③<br><br><b>Returns: maximized</b><br><small><em>after expenses & taxes*</em><br></small><br><b>Risk: optimized</b><br><small><em>multi-factor diversification<br>& index tracking error<br></em></small><br><b>Impact: quantified</b><br><small><em>CO₂ footprint & custom ESG metrics</em><br></small><br><br><br><small>*taxes are often the #1 drag on investment performance.</small></div>",
        

        "modal.labs" : "<h1 style='color: #3357ff;'>Powered by IMPACT Labs</h1>This portfolio simulator was built by the team at Impact Labs Inc.<br><div style='text-align:center;'><br>Tap below to learn more:<br><br><a href='https://ImpactLabs.com' title='ImpactLabs.com' style='color:white;'><div class='insideModalDiv' style='background-color: #3357ff; color: white; border-radius: 4px;'><br><span style='font-size: 150%;'><strong>IMPACT</strong><font color='silver'> Labs</font></span><small>.com</small>&nbsp;<br><br>Responsible Investing Redefined<br><br></div></a><br><br><img src='https://d3e1j90z5g3six.cloudfront.net/images/other/USSIF_2018alpha.png' style='width:100%; height:100%; border-radius: 4px;'><br><br><img src='https://s3-us-west-2.amazonaws.com/impactstatic/images/gifs/slidersWeapons_blue.gif' style='width:100%; height:100%; border-radius: 4px;'>",
        "modal.IA" : "<h1 style='color: #3357ff;'>Need an Advisor?</h1>Tech-Driven Wealth Management:<br><div style='text-align:center;'><br><a href='https://ImpactAdvisor.com' title='ImpactAdvisor.com'><img src='https://d3e1j90z5g3six.cloudfront.net/images/impact/IA_Modern_Wealth_Management_00.png' style='width:100%; height:100%; border-radius: 4px;'></a><div class='insideModalDiv' style='color: #3357ff; font-size: 120%; border-radius: 4px;'><br><b>Responsible Investing, Redefined.</b></div><div><table align='center' style='text-align: left;'><tr><td>①&nbsp;&nbsp;</td><td>Returns: net of fees & taxes</td></tr><tr><td>②&nbsp;&nbsp;</td><td>Risk: margin of safety</td></tr><tr><td>③&nbsp;&nbsp;</td><td>Relevant diversification·impact</td></tr><tr><td>④&nbsp;&nbsp;</td><td>Relationship choice</td></tr></table><a href='https://impactadvisor.com/#modernInvesting?fromAlpha' title='ImpactAdvisor.com'><small><em>learn more</em><br></small></a><br></div><a href='https://ImpactAdvisor.com' title='ImpactAdvisor.com' style='color:white;'></a><br><a href='https://my.artibot.ai/impactlabs' title='Retirement Readiness Assessment'><img src='https://d3e1j90z5g3six.cloudfront.net/images/other/RetirementReadiness.jpg' style='width:60%; border-radius: 4px;'></a></div>",
        "modal.v1" : "<h1 style='color: #2c7ec0;'>Personalized Direct Indexing</h1>Our customizable ESG optimizer:<br><br> <a href='https://ESG.ImpactLabs.com' style='color:white;'><div style='background-color: #2c7ec0; color: white; text-align:center; font-size: 150%; border-radius: 4px;'><br>ESG.ImpactLabs<small>.com</small><br><br></div></a><br><br><br>Tap below to see the original customizable ESG personalized index optimizer.<br><br><img src='https://d3e1j90z5g3six.cloudfront.net/images/gifs/slidersClimateChange_color.gif' style='width:auto; height:60px;'><br><br><img src='https://d3e1j90z5g3six.cloudfront.net/images/gifs/slidersSugar_color.gif' style='width:auto; height:60px;'><br><br><img src='https://d3e1j90z5g3six.cloudfront.net/images/gifs/slidersGenderEquality.gif' style='width:auto; height:60px;'><br><br><img src='https://d3e1j90z5g3six.cloudfront.net/images/gifs/slidersTobacco_color.gif' style='width:auto; height:60px;'>",
        "modal.v2" : "<h1 style='color: #3357ff;'>ESG.ImpactLabs.com</h1>Our customizable ESG optimizer:<br><br> <a href='https://ESG.ImpactLabs.com' style='color:white;'><div style='background-color: #3357ff; color: white; text-align:center; font-size: 150%; border-radius: 4px;'><div class='insideModalDiv'><br><span style='font-size:200%;'>🌎⚖️🛡</span><br>ESG.ImpactLabs<small>.com</small><br>&nbsp;</div></div></a><br>At Impact Labs, we believe in the power of private capital to effect positive change and create a better tomorrow.<br><br>And we believe the best way to unleash that power is through freedom of choice:<br><br><div style='background-color: #3357ff; color: white; text-align:center; font-size: 120%; border-radius: 4px;'><div class='insideModalDiv'>We empower you to invest with companies effecting positive change for a better tomorrow.<br><br>We offer an unprecedented freedom of choice.</div></div><br>Swipe right or swipe left to identify the causes or values that matter most to you, and our software takes care of the rest.<br><br><div style='background-color: #3357ff; color: white; text-align:center;  border-radius: 4px;'><div class='insideModalDiv'><big>Tax-Smart Direct-Indexing</big><br><br><span style='white-space:nowrap;'>It's Your Financial Footprint<span style='vertical-align: super; font-size: 0.5rem; line-height: 1em;'>℠</span></span></div></div>",
        "modal.taxCalc" : "<h1 style='color: #ee6e73;'>Like taxes?</h1>Learn about the #1 drag on building wealth:<br><br><div style='text-align:center;'><br><a href='https://tax.ImpactLabs.com#goToTaxCalc' style='color:white;'><div class='insideModalDiv' style='background-color: #ee6e73; color: white; text-align:center; border-radius: 4px;'><br><span style='font-size: 150%;'>tax.ImpactLabs</span><small>.com</small><br><br>Know your tax impacts; make smarter decisions.<br><br></div><br><br><a hidden href='https://tax.ImpactLabs.com#goToTaxCalc' ><img src='https://d3e1j90z5g3six.cloudfront.net/tax/deficit_vs_household_debt_clean.png'/></a>",
        "modal.veg" : "<h1 style='color: #01A901;'>Got Plant?</h1>A vegetarian's carbon foodprint is ½ that of a meat-lover's.<br><br>Livestock farming produces 20%-50% of all man-made greenhouse gas emissions.<br><br>Tap here for vegan and cruelty-free investing:<br><br><a href='https://Vegemize.com' style='color:white;'><div class='insideModalDiv' style='background-color: #01A901; color: white; text-align:center; border-radius: 4px;'><span style='font-size:450%;'>🌱</span><br><span style='font-size:150%;'>Vegemize</span><small>.com</small><br><br>Put your money <span style='white-space: nowrap'>where your mouth is.</span><br></div></a><br><a href='http://www.greeneatz.com/foods-carbon-footprint.html' ><img src='http://www.greeneatz.com/wp-content/uploads/2013/01/foods-carbon-footprint-7.gif' style='width:100%; height:100%;'></a>",
        "modal.SDG" : "<h1 style='color: #5b92e5;'>U.N. Sustainable Development Goals</h1>Tap below to visit our customizable SDG simulator:<br><br> <a href='https://CustomSDG.com' style='color:white;'><div class='insideModalDiv' style='background-color: #5b92e5; color: white; text-align:center; font-size: 150%; border-radius: 4px;'><span style='font-size:300%;'>" + dataConfig.emojiSDG + "</span><br><div style='margin-top:-0.5em;'>CustomSDG<small>.com</small></div></div></a><br>The United Nation's <a href='/sustainabledevelopment/sustainable-development-goals/'>Sustainable Development Goals or SDGs</a> are a call to action to promote prosperity while protecting the planet. They recognize that ending poverty must go hand-in-hand with strategies that build economic growth and address a range of social needs including education, health, social protection, and job opportunities, while tackling climate change and environmental protection.<br><br><div style='text-align:right'>Tap to continue:</div>",
        "modal.equality" : "<h1 style='color: #ff3a21;'>Diversity. Equality. Inclusion.</h1><br><a href='https://InvestForEquality.com' style='color:white;'><div class='insideModalDiv' style='background-color: #ff3a21; color: white; text-align:center; border-radius: 4px;'><small>tap to:<br></small><span style='font-size:140%;'>🌈 InvestForEquality<small>.com</small></span></a><br></div><br><center><img src='https://d3e1j90z5g3six.cloudfront.net/images/icons/png/genderEq_400x.png' style='width:64px; height:64px;'/></center><h1 style='text-align:center'><span style='text-align:center;'><small><a href='https://www.un.org/sustainabledevelopment/gender-equality/'>" + dataConfig.emojiSDGdot + "5<br></a></small>Gender Equality</span></h1>Today, women hold <a href='https://www.catalyst.org/research/women-ceos-of-the-sp-500/'><b>only ~6% of the CEO-positions</b></a> of the S&P 500 companies. At the same time, women control <a href='https://bmogamviewpoints.com/women/'><b>51% of personal wealth</b></a> in the U.S.<br><br>Here's a video with more from Melinda Gates:<br><br><div class='modalIframe'><iframe src='https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6564477179038621696' width='100%' height='500px' frameborder='0' allowfullscreen='' title='Embedded post'></iframe></div><br>With IMPACT<sub>x</sub> you can invest in companies with Equality Impact Metrics 'from the future'.",

    // last one does NOT end in a comma.
        'last.entry': 'placeholder'
    };
};

// notes for JasonE
// Button colors:   inside, border  -  approx
// Orange color:    #f2ae43, #f0a324  btn-danger
// Blue color:      #3e8acc, #307dbf
// Green color:     #58b957, #48af46
// Red color:       #db524b , #d63d34 btn-primary
// ImpactAdvisor 2017:           #3357ff
// ImpactAdvisor:           #1ac6ff   RGB: 26,188,255 (decimal)
// IA Grey in ImpactLabs:   #a7a7a7

// radar originally:
// fill="#8884d8"
// fill="#82ca9d"
// fill="#f58484"

// YearCharts: 3357ff, 243EB6, 8a9fff


//     /(sdg|xxx)/.test(window.location.hostname) ? '#5b92e5' :
//    /(veg|xxx)/.test(window.location.hostname) ? '#01A901' :
//    /(equality|xxx)/.test(window.location.hostname) ? '#ff3a21' :
//    /(pope|francis|xxx)/.test(window.location.hostname) ? '#911eb4' :
//    /(esg|xxx)/.test(window.location.hostname) ? '#3357ff' : '#3357ff';


