import React, { Component } from "react";
import { Layout, Modal, Button } from "antd";
import { Helmet } from "react-helmet";
import Fullscreen from "react-full-screen";
import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";
import { getFromStorage, setInStorage } from "localStorageService";

import Topbar from "../Topbar/index";
import { footerText } from "util/config";
import App from "routes/index";
import { connect } from "react-redux";
import {
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
    NAV_STYLE_MINI_SIDEBAR,
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    TAB_SIZE
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { iaText } from "config/iaText";
import { dataConfig } from "config/dataConfig";

const emojiSDG = dataConfig.emojiSDG;
const siteColor = dataConfig.siteColor;

const { Content, Footer } = Layout;

const confirm = Modal.info;

export class MainApp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    getContainerClass = navStyle => {
        switch (navStyle) {
            case NAV_STYLE_DARK_HORIZONTAL:
                return "gx-container-wrap";
            case NAV_STYLE_DEFAULT_HORIZONTAL:
                return "gx-container-wrap";
            case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
                return "gx-container-wrap";
            case NAV_STYLE_BELOW_HEADER:
                return "gx-container-wrap";
            case NAV_STYLE_ABOVE_HEADER:
                return "gx-container-wrap";
            default:
                return "";
        }
    };
    getNavStyles = navStyle => {
        switch (navStyle) {
            case NAV_STYLE_DEFAULT_HORIZONTAL:
                return <HorizontalDefault />;
            case NAV_STYLE_DARK_HORIZONTAL:
                return <HorizontalDark />;
            case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
                return <InsideHeader />;
            case NAV_STYLE_ABOVE_HEADER:
                return <AboveHeader />;
            case NAV_STYLE_BELOW_HEADER:
                return <BelowHeader />;
            case NAV_STYLE_FIXED:
                return <Topbar />;
            case NAV_STYLE_DRAWER:
                return <Topbar />;
            case NAV_STYLE_MINI_SIDEBAR:
                return <Topbar />;
            case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
                return <NoHeaderNotification />;
            case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
                return <NoHeaderNotification />;
            default:
                return null;
        }
    };

    getSidebar = (navStyle, width) => {
        if (width < TAB_SIZE) {
            return <Sidebar />;
        }
        switch (navStyle) {
            case NAV_STYLE_FIXED:
                return <Sidebar />;
            case NAV_STYLE_DRAWER:
                return <Sidebar />;
            case NAV_STYLE_MINI_SIDEBAR:
                return <Sidebar />;
            case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
                return <Sidebar />;
            case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
                return <Sidebar />;
            default:
                return null;
        }
    };

    renderMetaTags = () => {
        const weBelieveSection = (window.innerWidth <= 680 ?
            "<span class='insideModalEmphasis'>Create a better tomorrow.</span></div><br>" : 
            "<span class='insideModalEmphasis'>Exercise your <span style='white-space: nowrap;'>power of choice </span>to create <span style='white-space: nowrap;'>a better tomorrow.</span></div><br>");
        //<span class='fontGrey'>and that the best way to unleash that power is </span><div class='insideModalEmphasis'>with freedom of choice.</div><br>";
        const weEmpowerSection = "<div class='insideModalDiv insideModalEmphasis' style='background-color: " + siteColor + "; color: white; text-align:center; border-radius: 4px;'>" + (
               (dataConfig.SDG) ? "Sustainable & responsible investing, redefined<span style='vertical-align: super; font-size: 0.5em; line-height: 1em;'>℠</span>" :
               (dataConfig.Veg) ? "Green investing, redefined<span style='vertical-align: super; font-size: 0.5em; line-height: 1em;'>℠</span>" :
                "Custom Direct Indexing:<br /><span style='white-space: nowrap; '><small>The Power of Choice</small></span></span>"
            ) + "</div><br>";
        const weOfferSection = "<div class='insideModalDiv' style='background-color: " + siteColor + 
            "; color: white; text-align:center; border-radius: 4px;'><br><span style='white-space: nowrap;'>Your values; your choice</span>" + 
                (
                    (dataConfig.Veg) ?
                        ", <span style='white-space: nowrap'>including plant-based preferences.</span>" :
                    (dataConfig.Equ)  ?
                        ".<br><br>Today, women hold <a href='https://www.catalyst.org/research/women-ceos-of-the-sp-500/' style=''>only ~6% of the CEO-positions</a> of the S&P 500. At the same time, women control <a href='https://bmogamviewpoints.com/women/' style=''>51% of personal wealth</a> in the U.S.<br><br>Does this matter for you?" :
                    '.'
                ) + 
            "<br><br>IMPACT<sub>" + dataConfig.logoTextValueSub + "</sub> will optimize the rest.<br><br>" + 
                (
                    (dataConfig.Veg) ? 'Ready to put your money where your mouth is?<br><br>' : ''
                ) + 
            "</div>";
        const selectAlgos = "";
        // const selectAlgos = "<div class='insideModalDivOutline' >Three Pillars of Responsible Investing<span style='vertical-align: super; font-size: 0.5em; line-height: 1em;'>℠</span><br><br>① ② ③<br><br><b>Returns: maximized</b><br><small><em>after fees & taxes</em><br></small><br><b>Risk: optimized</b><br><small><em>multi-factor diversification<br>& index tracking error<br></em></small><br><b>Impact: quantified</b><br><small><em>CO₂ footprint & custom ESG metrics</em><br></small></div>";
        switch (true) {
            case dataConfig.SDG:
                return {
                    title:
                        "Custom SDG " +
                        emojiSDG +
                        "ESG 🌎 Responsible Investing",
                    description:
                        "Sustainable investing 🌎 🇺🇳 SDGs, powered by IMPACT LABS: Empowering High Performance Wealth Management · Tax-Smart · Customizable · Sustainable · Social-Impact. Our Mission is to inspire and empower everyone to invest intentionally. Achieve your financial and personal goals: invest in companies that align with your values and passions with the help of our software.",
                    appName:
                        "IMPACT LABS | Empowering High Performance Wealth Management: Socially-Selective; Tax-Smart",
                    keywords:
                        "SDG, Sustainable Development Goals, Impact Labs, Sustainable Investing, Social Impact, SRI, ESG, SDG, Socially-Selective, Customizable, Impact, Index, Investing, values, 401k, 401(k), environment, tax, custom esg, impact investing, Escamilla",
                    modalText:
                        "<div style='text-align: center;'><h1 style='color: " + siteColor + "'>Impact Labs</h1>" +
                        "Sustainable 🌎 ESG·SDG " + emojiSDG + " Tax-Smart</span><br>Direct-Indexing<br><br>" +
                        weEmpowerSection + "<span style='color: " + siteColor + "'>" + weBelieveSection + "</span>" + weOfferSection + selectAlgos + "</div>"
                };
            case dataConfig.Veg:
                return {
                    title:
                        "Vegan 🌱Sustainable Investing · ESG " +
                        emojiSDG +
                        "SDG · Direct-Indexing: IMPACT Labs",
                    description:
                        "Vegan 🌱 sustainable investing 🌎IMPACT LABS: Responsible investing, redefined℠ · Tax-Smart · Customizable · Sustainable · Social-Impact. Our Mission is to inspire and empower everyone to invest intentionally. Achieve your financial and personal goals: invest in companies that align with your values and passions with the help of our software.",
                    appName:
                        "IMPACT LABS | Direct-Indexing",
                    keywords:
                        "vegan, Sustainable Investing, Social, Impact, SRI, ESG, SDG, Socially-Selective, Customizable, Index, Investing, values, 401k, 401(k), environment, tax, custom esg, impact investing, Escamilla",
                    modalText:
                        "<div style='text-align: center;'><h1 style='color: " + siteColor + "'><big>🌱</big><br>Welcome to Vegemize</h1>First-of-a-Kind Vegan Direct Indexing Customizer<br><br>" +
                        weEmpowerSection + "<span style='color: " + siteColor + "'>" + weBelieveSection + "</span>" + weOfferSection + selectAlgos +
                        "</div>"
                };
            case dataConfig.VJ:
                return {
                    title:
                        "VJ 🌎Custom ESG " +
                        emojiSDG +
                        "SDG · Direct-Indexing",
                    description:
                        "Vegan 🌱 sustainable investing 🌎IMPACT LABS: Responsible investing, redefined℠ · Tax-Smart · Customizable · Sustainable · Social-Impact. Our Mission is to inspire and empower everyone to invest intentionally. Achieve your financial and personal goals: invest in companies that align with your values and passions with the help of our software.",
                    appName:
                        "IMPACT LABS | Direct-Indexing",
                    keywords:
                        "vegan, Sustainable Investing, Social, Impact, SRI, ESG, SDG, Socially-Selective, Customizable, Index, Investing, values, 401k, 401(k), environment, tax, custom esg, impact investing, Escamilla",
                    modalText:
                        "<div style='text-align: center;'><h1 style='color: " + siteColor + "'><big>🌱</big><br>IMPACT<sub>" + dataConfig.logoTextValueSub + "</sub></h1>IMPACT Optimizer Demo<br><br>" +
                        weEmpowerSection + "<span style='color: " + siteColor + "'>" + weBelieveSection + "</span>" + weOfferSection + selectAlgos +
                        "</div>"
                };
            case dataConfig.ESG:
            case 'X':
            default:
                return {
                    title:
                        "IMPACTx 🌎Responsible Investing " +
                        "Custom Direct Indexing",
                    description:
                        "Sustainable investing 🌎IMPACT LABS: Empowering High Performance Wealth Management · Tax-Smart · Customizable · Sustainable · Social-Impact. Our Mission is to inspire and empower everyone to invest intentionally. Achieve your financial and personal goals: invest in companies that align with your values and passions with the help of our software.",
                    appName:
                        "IMPACT LABS | Empowering High Performance Wealth Management: Socially-Selective; Tax-Smart",
                    keywords:
                        "Impact Labs, Sustainable Investing, Social Impact, SRI, ESG, SDG, Socially-Selective, Customizable, Impact, Index, Investing, values, 401k, 401(k), environment, tax, custom esg, impact investing, Escamilla",
                    modalText:
                        "<div style='text-align: center;'><h1 style='font-size: 2rem; color: " + siteColor + "'>IMPACT<sub>" + dataConfig.logoTextValueSub + "</sub></h1>" +
                        "" +
                        weEmpowerSection + "<span style='color: " + siteColor + "'>" + weBelieveSection + "</span>" + weOfferSection + selectAlgos +
                        "<br></div>"
                };
        }
    };
//<img src='https://d3e1j90z5g3six.cloudfront.net/images/impact/labs_logo_white-alpha-400-440x.png' style='height:20px; margin-bottom: 10px; background-color:" + siteColor + "; border-radius:3px;'>
    componentDidMount() {
        this.openFirstVisitModal();
    }

    openFirstVisitModal = () => {
        const alreadyOpened = getFromStorage("modalOpened") || '';
        if (!alreadyOpened) {
            this.setState({
                open: true
            });
        }
    };

    onClose = () => {
        this.setState(
            {
                open: false
            },
            () => {
                setInStorage("modalOpened", true);
            }
        );
    };
    onCancel = () => {
        // disabled in the hopes user reads more of the Welcome Modal.
        console.log("onCancel tapped (disabled)");
    };

    modalShowPerf = (ev) => {
        const textList = iaText();
        const text = textList["confirmModal.modalShowPerf.html"];
        const modalTitle = textList["confirmModal.modalShowPerf.title"];
        const iconUrl = textList["confirmModal.modalShowPerf.icon"];
        confirm({
            title: modalTitle,
            icon: <img src={iconUrl} alt="" />,
            maskClosable: true,
            content: (
                <div
                    dangerouslySetInnerHTML={{
                        __html: '<hr/>' + text
                    }}
                />
            ),
            onOk() {}
        });
    };

    modalRespInvest = (ev) => {
        const textList = iaText();
        const text = textList["confirmModal.modalRespInvest.html"];
        const modalTitle = textList["confirmModal.modalRespInvest.title"];
        const iconUrl = textList["confirmModal.modalRespInvest.icon"];
        confirm({
            title: modalTitle,
            icon: <img src={iconUrl} alt="" />,
            maskClosable: true,
            content: (
                <div
                    dangerouslySetInnerHTML={{
                        __html: '<hr/>' + text
                    }}
                />
            ),
            onOk() {}
        });
    };

    render() {
        const { match, width, navStyle } = this.props;
        //const text = iaText();
        const {
            title,
            description,
            appName,
            keywords,
            modalText
        } = this.renderMetaTags();
        const closeButtonText = (window.innerWidth >= 360 ? "Got it. " : "") +
            (dataConfig.SDG) ? "OK" :
            (dataConfig.Veg) ? "Let's define green investing!" :
                "Let's define responsible investing!";
        return (
            <Fullscreen enabled={false}>
                <Layout className="gx-app-layout">
                    {this.getSidebar(navStyle, width)}
                    <Layout>
                        {this.getNavStyles(navStyle)}
                        <Content
                            className={`gx-layout-content ${this.getContainerClass(
                                navStyle
                            )} `}
                        >
                            <Helmet>
                                <title>{title}</title>
                                <meta
                                    name="description"
                                    content={description}
                                />
                                <meta name="keywords" content={keywords} />
                                <meta
                                    name="application-name"
                                    content={appName}
                                />
                                <meta name="keywords" content={appName} />
                            </Helmet>
                            <App match={match} />
                            <Footer>
                                <div className="gx-layout-footer-content" style={{color: siteColor}}>
                                    {footerText}
                                </div>
                            </Footer>
                            <Modal
                                onCancel={this.onCancel}
                                visible={this.state.open}
                                closable={false}
                                footer={[
                                    <Button key="back" onClick={this.onClose}
                                        style={{background: siteColor, color: 'white'}}>
                                        {closeButtonText}
                                    </Button>
                                ]}
                            >
                                <div
                                    className="headerModals"
                                    dangerouslySetInnerHTML={{
                                        __html: modalText
                                    }}
                                />
                            </Modal>
                        </Content>
                    </Layout>
                </Layout>
            </Fullscreen>
        );
    }
}
                                // <br />
                                // <div onClick={ev => this.modalRespInvest( ev)}
                                //     dangerouslySetInnerHTML={{
                                //         __html: text["confirmModal.modalRespInvest.imageTap"]
                                //     }}
                                // />
                                // <br />
                                // <div onClick={ev => this.modalShowPerf( ev)}
                                //     dangerouslySetInnerHTML={{
                                //         __html: text["confirmModal.modalShowPerf.imageTap"]
                                //     }}
                                // />
                                
const mapStateToProps = ({ settings }) => {
    const { width, navStyle } = settings;
    return { width, navStyle };
};
export default connect(mapStateToProps)(MainApp);
