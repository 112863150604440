export default [
    {
        category: "sustainabilityCause",
        cause: "climateChange"
    },
    {
        category: "socialCause",
        cause: "women"
    },
    {
        category: "socialCause",
        cause: "sdg"
    },
    {
        category: "sustainabilityCause",
        cause: "carbonNeutral"
    },
    {
        category: "sustainabilityCause",
        cause: "fossilFuels"
    },
    {
        category: "drugsCause",
        cause: "tobacco"
    },
    {
        category: "peaceCause",
        cause: "military"
    },
    {
        category: "peaceCause",
        cause: "secondAmendment"
    },
];