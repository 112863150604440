export const setInStorage = (key, value) =>
    localStorage.setItem(key, JSON.stringify(value));

export const getFromStorage = (key = '') => {
	const localStorageKey = localStorage.getItem(key);
	if (localStorageKey === "undefined") {
		return undefined;
	} else if(localStorageKey === undefined) {
		return undefined;
	}

	return JSON.parse(localStorage.getItem(key))
}
    

export const removeItem = (key) =>
    localStorage.removeItem(key);
