import { dataConfig } from "config/dataConfig";
import { getFromStorage } from "localStorageService";

export const keys = () => {

    const dbKey = getFromStorage('db') || dataConfig.dbKey || '⭕️⭕️⭕️ error: both keys null ⭕️⭕️⭕️';
    console.log("🔥firebaseSwitch getFromStorage('db'):", dbKey);

    // Stendeck: switch colors here?
    switch (true) {
        case /(dev|beta|⭕️⭕️⭕️)/.test(dbKey):
            console.log('⚙️ dev 🔥DB: betaalphaesg' );
            return {
                apiKey: "AIzaSyBb1DGO_MwwsHS-x2XoKQTEfLTtiYHubFk",
                authDomain: "betaalphaesg.firebaseapp.com",
                databaseURL: "https://betaalphaesg.firebaseio.com",
                projectId: "betaalphaesg",
                storageBucket: "betaalphaesg.appspot.com",
                messagingSenderId: "967510247940"
            };
        case /(esg)/.test(dbKey):
            console.log('🌎 ESG: 🔥DB: customesg' );
            return {
                apiKey: "AIzaSyBKkR7uk4w6QdFtj706OlYjooShJIaIa64",
                authDomain: "customesg.firebaseapp.com",
                databaseURL: "https://customesg.firebaseio.com",
                projectId: "customesg",
                storageBucket: "customesg.appspot.com",
                messagingSenderId: "625455272639"
            };
        case (dbKey === 'sdg'):
            console.log('🇺🇳 SDG 🔥DB: customsdg' );
            return {
                apiKey: "AIzaSyCz2N4vpkR9Knz0u6c9ENsJr1OIJKRoObU",
                authDomain: "customsdg-b7b5d.firebaseapp.com",
                databaseURL: "https://customsdg-b7b5d.firebaseio.com",
                projectId: "customsdg-b7b5d",
                storageBucket: "customsdg-b7b5d.appspot.com",
                messagingSenderId: "905958488783"
            };
        case (dbKey === 'veg'):
            console.log('🌱 Veg 🔥DB: vegemize' );
            return {
                apiKey: "AIzaSyAi-mqUyduo8xSjn97GGdJcNxgo1SfbUSg",
                authDomain: "vegemize.firebaseapp.com",
                databaseURL: "https://vegemize.firebaseio.com",
                projectId: "vegemize",
                storageBucket: "vegemize.appspot.com",
                messagingSenderId: "69387786003"
            };
        case (dbKey === 'vj'):
            console.log('🌱 VJ demo 🔥DB: IMPACTv' );
            return {
                apiKey: "AIzaSyBk_Yoi2BEUqcQqK34U8Ha4QolJHODFOCU",
                authDomain: "impact-demo.firebaseapp.com",
                databaseURL: "https://impact-demo.firebaseio.com",
                projectId: "impact-demo",
                storageBucket: "impact-demo.appspot.com",
                messagingSenderId: "69387786003"
            };
        case (dbKey === 'green'):
            console.log('🌱 🔥DB: impact-green' );
            return {
                apiKey: "AIzaSyAi-mqUyduo8xSjn97GGdJcNxgo1SfbUSg",
                authDomain: "impact-green.firebaseapp.com",
                databaseURL: "https://impact-green.firebaseio.com",
                projectId: "impact-green",
                storageBucket: "impact-green.appspot.com",
                messagingSenderId: "69387786003"
            };
        case (dbKey === 'aslphaesg'):
            console.log('🔥DB: aslphaesg⛔️⛔️⛔️⛔️⛔️⛔️  not used anywhere ?' );
            return {
                apiKey: "AIzaSyCbfMzem7WqfjZrq2N6VMeh_cJztezgyNw",
                authDomain: "aslphaesg.firebaseapp.com",
                databaseURL: "https://aslphaesg.firebaseio.com",
                projectId: "aslphaesg",
                storageBucket: "aslphaesg.appspot.com",
                messagingSenderId: "69387786003"
            };
        default:
            console.log('Default switch 🔥DB: customesg 🌎 ESG ⛔️⛔️⛔️⛔️⛔️' );
            return {
                apiKey: "AIzaSyBKkR7uk4w6QdFtj706OlYjooShJIaIa64",
                authDomain: "customesg.firebaseapp.com",
                databaseURL: "https://customesg.firebaseio.com",
                projectId: "customesg",
                storageBucket: "customesg.appspot.com",
                messagingSenderId: "625455272639"
            };
    }
};
