export default [
    {
        category: "sustainabilityCause",
        cause: "climateChange"
    },
    {
        category: "foodCause",
        cause: "vegan"
    },
    {
        category: "peaceCause",
        cause: "animals"
    },
    {
        category: "sustainabilityCause",
        cause: "sustainableAgriculture"
    },
    {
        category: "socialCause",
        cause: "women"
    },
    {
        category: "peaceCause",
        cause: "military"
    },
    {
        category: "foodCause",
        cause: "sugar"
    }
];