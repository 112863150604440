import { handleActions } from "redux-actions";
import {
    GET_ALL_CAUSES_START,
    GET_ALL_CAUSES_SUCCESS,
    GET_ALL_CAUSES_FAIL,
    GET_DEFAULT_CAT_START,
    GET_DEFAULT_CAT_SUCCESS,
    GET_DEFAULT_CAT_FAIL,
    GET_CUSTOM_CAT_START,
    GET_CUSTOM_CAT_SUCCESS,
    GET_CUSTOM_CAT_FAIL,
    GET_DEFAULT_CAUSES_START,
    GET_DEFAULT_CAUSES_SUCCESS,
    GET_DEFAULT_CAUSES_FAIL,
    GET_SETTINGS_START,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAIL,
    UPDATE_VISIBILITY,
    UPDATE_CAUSES_START,
    UPDATE_CAUSES_SUCCESS,
    UPDATE_CAUSES_FAIL
} from "./constants";

const initialState = {
    causes: [],
    isLoading: false,
    isSavingCauses: false,
    errorMessage: ""
};

export default handleActions(
    {
        [UPDATE_CAUSES_START]: state => ({
            ...state,
            isSavingCauses: true
        }),
        [UPDATE_CAUSES_SUCCESS]: (state, payload) => {
            return {
                ...state,
                causes: payload.causes,
                isSavingCauses: false
            };
        },
        [UPDATE_CAUSES_FAIL]: (state, payload) => ({
            ...state,
            errorMessageWhenSaving: payload,
            isSavingCauses: false
        }),

        [GET_ALL_CAUSES_START]: state => ({
            ...state,
            isLoading: true
        }),
        [GET_ALL_CAUSES_SUCCESS]: (state, payload) => {
            return {
                ...state,
                causes: payload.causes,
                isLoading: false
            };
        },
        [GET_ALL_CAUSES_FAIL]: (state, payload) => ({
            ...state,
            errorMessage: payload,
            isLoading: false
        }),

        [GET_DEFAULT_CAT_START]: state => ({
            ...state,
            isLoading: true
        }),
        [GET_DEFAULT_CAT_SUCCESS]: (state, payload) => {
            return {
                ...state,
                defaultCategories: payload.defaultCategories,
                isLoading: false
            };
        },
        [GET_DEFAULT_CAT_FAIL]: (state, payload) => ({
            ...state,
            errorMessage: payload,
            isLoading: false
        }),

        [GET_CUSTOM_CAT_START]: state => ({
            ...state,
            isLoading: true
        }),
        [GET_CUSTOM_CAT_SUCCESS]: (state, payload) => {
            return {
                ...state,
                customCategories: payload.customCategories,
                isLoading: false
            };
        },
        [GET_CUSTOM_CAT_FAIL]: (state, payload) => ({
            ...state,
            errorMessage: payload,
            isLoading: false
        }),

        [GET_DEFAULT_CAUSES_START]: state => ({
            ...state,
            isLoading: true
        }),
        [GET_DEFAULT_CAUSES_SUCCESS]: (state, payload) => {
            return {
                ...state,
                defaultCauses: payload.defaultCauses,
                isLoading: false
            };
        },
        [GET_DEFAULT_CAUSES_FAIL]: (state, payload) => ({
            ...state,
            errorMessage: payload,
            isLoading: false
        }),

        [GET_SETTINGS_START]: state => ({
            ...state,
            isLoading: true
        }),
        [GET_SETTINGS_SUCCESS]: (state, payload) => {
            return {
                ...state,
                settings: payload.settings,
                isLoading: false
            };
        },
        [GET_SETTINGS_FAIL]: (state, payload) => ({
            ...state,
            errorMessage: payload,
            isLoading: false
        }),

        [UPDATE_VISIBILITY]: (state, payload) => {
            const { category, cause, visibility } = payload;
            return {
                ...state,
                defaultCauses: {
                    ...state.defaultCauses,
                    [category]: {
                        ...state.defaultCauses[category],
                        [cause]: {
                            ...state.defaultCauses[category][cause],
                            causeVisible: visibility,
                            causeEnabled: visibility
                        }
                    }
                }
            };
        }
    },
    initialState
);
