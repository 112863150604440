import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
    <div className="gx-main-content-wrapper">
        <Switch>
            <Route
                path={`${match.url}portfolio`}
                component={asyncComponent(() => import("containers/Portfolio"))}
            />
            <Route
                path={`${match.url}settings`}
                component={asyncComponent(() => import("containers/Settings"))}
            />
        </Switch>
    </div>
);

export default App;
