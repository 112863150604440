export const GET_CAUSES_START = "GET_CAUSES_START";
export const GET_CAUSES_SUCCESS = "GET_CAUSES_SUCCESS";
export const GET_CAUSES_FAIL = "GET_CAUSES_FAIL";
export const SET_CAUSE = "SET_CAUSE";
export const SET_TICKER = "SET_TICKER";
export const TICKERS_START = "TICKERS_START";
export const TICKERS_SUCCESS = "TICKERS_SUCCESS";
export const TICKERS_FAIL = "TICKERS_FAIL";
export const IMPACT_POINTS_START = "IMPACT_POINTS_START";
export const IMPACT_POINTS_SUCCESS = "IMPACT_POINTS_SUCCESS";
export const IMPACT_POINTS_FAIL = "IMPACT_POINTS_FAIL";
export const RESET = "RESET";
export const SET_IMPACT_POINT_SUCCESS = "SET_IMPACT_POINT_SUCCESS";
export const SET_IMPACT_POINT_FAIL = "SET_IMPACT_POINT_FAIL";
export const RESET_APP_DB_VERSION = "RESET_APP_DB_VERSION";
export const SET_GEO_LOCATION = "SET_GEO_LOCATION";
